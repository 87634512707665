import { Box, Button, Center, CenterProps, Stack } from "@chakra-ui/react";
import React from "react";
import { QRCode } from "react-qrcode-logo";
import logo from "./../assets/qrCodeLogo.png";

interface QRCodeGeneratorProps extends CenterProps {
  url: string;
  button?: boolean;
  size?: number;
  logoWidth?: number;
  //   iconUrl: string;
}

const QRCodeGenerator: React.FC<QRCodeGeneratorProps> = ({
  url,
  button,
  size,
  logoWidth,
  ...centerProps
}) => {
  const downloadQRCode = () => {
    const canvas = document.getElementById("qrcode") as HTMLCanvasElement;
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      const downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "qrcode.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <Center {...centerProps}>
      <Box>
        <QRCode
          id="qrcode"
          value={url}
          logoImage={logo}
          logoWidth={logoWidth ? logoWidth : 60}
          eyeRadius={[10, 10, 10, 10]}
          size={size ? size : 256}
        />
        {!button && (
          <Stack direction="row" spacing={4} justifyContent="center" mt={4}>
            <Button onClick={downloadQRCode} colorScheme="teal">
              Download QR Code
            </Button>
          </Stack>
        )}
      </Box>
    </Center>
  );
};

export default QRCodeGenerator;
