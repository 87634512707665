import PageHeader from "../../../components/PageHeader";
import { Loading, NoData } from "../../../components/Common/Components";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteShareVideo,
  getAllShareVideos,
} from "../../../Redux/action/video.action";
import { useNavigate } from "react-router-dom";
import { BiVideoOff } from "react-icons/bi";
import VideoCard from "./VideoCard";
import ErrorCard from "../../../components/Common/ErrorCard";
import useDocumentTitle from "../../../interceptor/useDocumentTitle";

const VideoAdminPage = () => {
  useDocumentTitle("Videos");
  const PAGE_SIZE = 6;
  const [data, setData] = useState<any[]>([]); // set initial data
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [shareVideosToDelete, setshareVideosToDelete] = useState<any>(null);

  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();

  const _allShareVideos = useSelector(
    (state: { allShareVideos: any }) => state.allShareVideos
  );
  const { loading: shareVideosLoading, shareVideos, error } = _allShareVideos;

  const { loading: shareVideosDeleteLoading, message: DeleteshareVideos } =
    useSelector((state: { deleteShareVideo: any }) => state.deleteShareVideo);

  const toastPositions = useSelector((state: any) => state.toastPosition);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleOpenNewModal = () => {
    navigate("/v1/video/add");
  };

  const handleDelete = (project: any) => {
    setshareVideosToDelete(project);
    setDeleteConfirmationOpen(true);
  };

  const handleConfirmDelete = () => {
    // Perform the delete action here
    if (shareVideosToDelete) {
      dispatch<any>(
        deleteShareVideo(shareVideosToDelete._id, toastPositions, toast)
      );
    }
    // Close the delete confirmation modal
    setDeleteConfirmationOpen(false);
  };

  useEffect(() => {
    dispatch<any>(getAllShareVideos(toastPositions, toast));
  }, [dispatch, DeleteshareVideos, toast]);

  useEffect(() => {
    const startIndex = (page - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;

    setData(shareVideos);
    setFilteredData(
      data
        ?.filter((d) =>
          d.title?.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .slice(startIndex, endIndex)
    );
  }, [shareVideos, data, shareVideosLoading, searchTerm, page]);

  return (
    <>
      <PageHeader
        onSearchChange={handleSearchChange}
        onButtonClick={handleOpenNewModal}
        searchTerm={searchTerm}
        listLength={data?.length}
        name="Video"
        button={true}
      />

      {error ? (
        <Flex
          height={"50vh"}
          width={"full"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box width={"200px"}>
            <ErrorCard message={error} />
          </Box>
        </Flex>
      ) : shareVideosLoading || shareVideosDeleteLoading ? (
        <Loading />
      ) : (
        <>
          {filteredData?.length > 0 ? (
            <>
              <Grid
                templateColumns="repeat(auto-fill, minmax(230px, 1fr))"
                gap={6}
              >
                {filteredData?.map((video) => (
                  <VideoCard
                    key={video._id}
                    video={video}
                    onDelete={handleDelete}
                  />
                ))}
              </Grid>
              {data?.length > PAGE_SIZE && (
                <HStack mt={6}>
                  {Array.from(
                    Array(Math.ceil(data?.length / PAGE_SIZE)),
                    (_, i) => i + 1
                  ).map((p) => (
                    <Button
                      key={p}
                      variant={page === p ? "solid" : "outline"}
                      size="sm"
                      onClick={() => handlePageChange(p)}
                    >
                      {p}
                    </Button>
                  ))}
                </HStack>
              )}
            </>
          ) : (
            <NoData icon={BiVideoOff} />
          )}
        </>
      )}

      {/* delete */}
      <Modal
        isOpen={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        size="sm"
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          backdropFilter={"blur(20px)"}
          background={useColorModeValue("whiteAlpha.500", "blackAlpha.600")}
          borderWidth={"1px"}
          borderRadius={"15px"}
        >
          <ModalHeader>Delete Video</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete this Video?</Text>
          </ModalBody>
          <ModalFooter gap={"10px"}>
            <Button colorScheme="red" onClick={handleConfirmDelete}>
              Delete
            </Button>
            <Button onClick={() => setDeleteConfirmationOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default VideoAdminPage;
