import {
  Box,
  Heading,
  Text,
  Switch,
  Button,
  useColorMode,
  FormControl,
  FormLabel,
  useToast,
  Stack,
  Select,
  Divider,
  Spacer,
  ToastPosition,
} from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToastPositionAction } from "../../../Redux/action/settingsAction";
import useDocumentTitle from "../../../interceptor/useDocumentTitle";

enum ValidPositions {
  Top = "top",
  TopRight = "top-right",
  TopLeft = "top-left",
  Bottom = "bottom",
  BottomRight = "bottom-right",
  BottomLeft = "bottom-left",
}

const Settings = () => {
  useDocumentTitle("Settings");
  const toastPositions = useSelector((state: any) => state.toastPosition);

  const { colorMode, toggleColorMode } = useColorMode();
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState("en");
  const [toastPosition, setToastPosition] =
    useState<ToastPosition>(toastPositions);
  const toast = useToast();
  const dispatch = useDispatch();
  // const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [notifications, setNotifications] = useState(false);

  // const [isOpen, setIsOpen] = useState(false);

  // const onClose = () => setIsOpen(false);
  // const onOpen = () => setIsOpen(true);

  // const handleSaveSettings = () => {
  //     localStorage.setItem('toastPosition', toastPosition);
  //     dispatch<any>(setToastPositionAction(toastPosition));
  //     // show success message with the selected toast position
  //     setLoading(true);
  //     toast({
  //         title: "Settings updated.",
  //         status: "success",
  //         duration: 3000,
  //         position: toastPosition,
  //         isClosable: true,
  //     });
  // };

  // const handleLockAccount = () => {
  //     // add logic to lock account
  //     onClose();
  // };
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setLoading(true);

    localStorage.setItem("toastPosition", toastPosition);
    dispatch<any>(setToastPositionAction(toastPosition));

    setTimeout(() => {
      setLoading(false);
      toast({
        title: "Settings updated.",
        status: "success",
        position: toastPosition,
        duration: 3000,
        isClosable: true,
      });
    }, 2000);
  };

  return (
    <>
      <Box px={4} py={8} maxW="xl" mx="auto">
        <Heading mb={2}>Settings</Heading>
        <Spacer />
        <Divider mb={8} />

        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <Box>
              <Text fontSize="md" fontWeight="bold">
                Theme
              </Text>
              <Text
                background={
                  colorMode === "dark" ? "whiteAlpha.200" : "blackAlpha.200"
                }
                borderRadius={"6px"}
                padding={"4px"}
                maxWidth={"max-content"}
                fontSize="md"
                color="gray.500"
                mt={2}
              >
                {colorMode === "dark" ? "Dark Mode" : "Light Mode"}
              </Text>
              <Switch
                colorScheme={"teal"}
                isChecked={colorMode === "dark"}
                onChange={toggleColorMode}
                mt={2}
              />
            </Box>
            {/* <Box>
                            <Text fontSize="xl" fontWeight="bold">
                                Push notifications
                            </Text>
                            <Switch colorScheme="teal" mt={2} />
                        </Box> */}
            {/* <Box>
                            <Text fontSize="xl" fontWeight="bold">
                                Location services
                            </Text>
                            <Switch colorScheme="teal" mt={2} />
                        </Box> */}

            <FormControl>
              <FormLabel>Language</FormLabel>
              <Select
                colorScheme="teal"
                isDisabled
                placeholder="Select your language"
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
              >
                <option value="en">English</option>
                <option value="es">Español</option>
                <option value="fr">Français</option>
              </Select>
            </FormControl>
            <Box>
              <Text fontSize={"md"} fontWeight="bold">
                Notifications Position
              </Text>
              <Select
                colorScheme="teal"
                placeholder="Select toast position"
                mt={2}
                value={toastPosition}
                onChange={(e) =>
                  setToastPosition(e.target.value as ToastPosition)
                }
              >
                <option value="top">Top</option>
                <option value="top-right">Top Right</option>
                <option value="top-left">Top Left</option>
                <option value="bottom">Bottom</option>
                <option value="bottom-right">Bottom Right</option>
                <option value="bottom-left">Bottom Left</option>
              </Select>
              {/* <Button mt={4} onClick={handleSaveSettings}>
                                Save Settings
                            </Button> */}
            </Box>
            <Button
              mt={8}
              colorScheme="teal"
              isLoading={loading}
              loadingText="Saving settings..."
              onClick={handleSubmit}
            >
              Save Setting
            </Button>
            <Divider my={8} />
            {/* <Button variant="outline" colorScheme="red" onClick={onOpen}>
                            Lock Account
                        </Button> */}
            {/* <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>Confirm</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <Text>Are you sure you want to lock your account?</Text>
                                </ModalBody>
                                <ModalFooter>
                                    <Button variant="ghost" mr={3} onClick={onClose}>
                                        Cancel
                                    </Button>
                                    <Button colorScheme="red" onClick={handleLockAccount}>
                                        Lock Account
                                    </Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal> */}
            <Spacer />
            {/* <Box>
                            <Text fontSize="sm" color="gray.500">
                                © 2023 My App. All rights reserved.
                            </Text>
                        </Box> */}
          </Stack>
        </form>
      </Box>
    </>
  );
};

export default Settings;
