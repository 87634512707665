import { useEffect } from "react";
import { truncateWords } from "../util/uitil";

function useDocumentTitle(title: any) {
  useEffect(() => {
    document.title =
      truncateWords(title ? title : "Maki Gifts", 15) + " - Maki Gifts";
  }, [title]);
}

export default useDocumentTitle;
