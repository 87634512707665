import {
  Box,
  Button,
  Container,
  Divider,
  FormLabel,
  Input,
  Select,
  Stack,
  StackDivider,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import CustomFormControl from "./CustomFormControl";
import { useEffect, useState } from "react";
// import { initialData } from "../../Management/Common/data";
import { useDispatch, useSelector } from "react-redux";
import { editPersonalAccount } from "../../../../Redux/action/user.action";

function FormStack() {
  const [userData, setUserData] = useState<any>({
    _id: "",
    username: "",
    email: "",
    profilePic: "",
    Role: "",
    password: "",
    Status: "",
    createdAt: "",
    updatedAt: "",
  });
  const dispatch = useDispatch();
  const toast = useToast();
  const toastPositions = useSelector((state: any) => state.toastPosition);

  const { userInfo } = useSelector(
    (state: { loggedInUser: any }) => state.loggedInUser
  );

  const { loading } = useSelector(
    (state: { personalAccount: any }) => state.personalAccount
  );

  useEffect(() => {
    setUserData({
      username: userInfo.username,
      Status: userInfo.Status,
    });
  }, [userInfo]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;

    if (type === "checkbox") {
      const checked = (e.target as HTMLInputElement).checked;
      setUserData((prevData: any) => ({
        ...prevData,
        [name]: checked,
      }));
    } else if (type === "select-multiple") {
      const selectedOptions = Array.from(
        (e.target as HTMLSelectElement).selectedOptions
      ).map((option) => option.value);
      setUserData((prevData: any) => ({
        ...prevData,
        [name]: selectedOptions,
      }));
    } else {
      setUserData((prevData: any) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch<any>(
      editPersonalAccount(
        {
          username: userData.username,
          password: userData.password,
          Status: userData.Status,
        },
        toastPositions,
        toast
      )
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Container
          maxWidth={"7xl"}
          borderRadius={"10px"}
          padding={"1rem!important"}
          boxShadow={"0 0 12px -10px"}
          // background={useColorModeValue("white", "gray.800")}
        >
          <Stack>
            <Stack flexDirection={"row"} justifyContent={"space-between"}>
              <Stack padding={"12px 0"}>
                <Text fontSize={"lg"} fontWeight={"medium"}>
                  Edit Your Profile
                </Text>
                <Text
                  color={"#4a5568"}
                  marginTop={"0!important"}
                  fontSize={"sm"}
                >
                  Tell others who you are
                </Text>
              </Stack>
              {/* <Button>Save</Button> */}
            </Stack>
            <Divider marginBottom={"15px !important"} margin={"10px 0px"} />
            <Stack>
              <CustomFormControl
                children={
                  <>
                    <FormLabel
                      marginBottom={0}
                      //   alignSelf={"center"}
                      minWidth={"2xs"}
                    >
                      User name
                    </FormLabel>
                    <Input
                      value={userData.username}
                      onChange={handleInputChange}
                      name="username"
                      marginTop={"0!important"}
                      background={useColorModeValue("white", "gray.800")}
                      placeholder="enter User Name"
                      _placeholder={{ color: "gray.500" }}
                      type="text"
                    />
                  </>
                }
              />

              <StackDivider
                margin={"10px 0 !important"}
                borderBottomWidth={"0"}
              />

              <CustomFormControl
                children={
                  <>
                    <FormLabel
                      fontSize={"sm"}
                      fontWeight={"medium"}
                      marginBottom={0}
                      minWidth={"2xs"}
                    >
                      Status
                    </FormLabel>
                    <Select
                      background={useColorModeValue("white", "gray.800")}
                      name="Status"
                      value={userData.Status ? "active" : "inactive"}
                      onChange={handleInputChange}
                    >
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </Select>
                  </>
                }
              />
              <StackDivider
                margin={"10px 0 !important"}
                borderBottomWidth={"0"}
              />

              <CustomFormControl
                children={
                  <>
                    <FormLabel
                      fontSize={"sm"}
                      fontWeight={"medium"}
                      marginBottom={0}
                      minWidth={"2xs"}
                    >
                      Enter Your Password To Save
                    </FormLabel>
                    <Input
                      value={userData.password}
                      onChange={handleInputChange}
                      name="password"
                      marginTop={"0!important"}
                      background={useColorModeValue("white", "gray.800")}
                      placeholder="enter your password..."
                      _placeholder={{ color: "gray.500" }}
                      type="password"
                    />
                  </>
                }
              />
              <StackDivider
                margin={"10px 0 !important"}
                borderBottomWidth={"0"}
              />

              <Box display={"flex"} flexDirection={"row-reverse"}>
                <Button
                  // onClick={handleSubmit}
                  isLoading={loading}
                  type="submit"
                  colorScheme="teal"
                >
                  Save
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Container>
      </form>
    </>
  );
}

export default FormStack;
