import { useState } from "react";
import AuthForm from "../components/AuthForm";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { SignUp, SignUpVerify } from "../Redux/action/user.action";
import useDocumentTitle from "../interceptor/useDocumentTitle";

const SignUpPage = () => {
  useDocumentTitle("Sign Up");
  const toastPositions = useSelector((state: any) => state.toastPosition);
  const [otpModal, setOtpModal] = useState(false);

  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const onOtpOpen = () => {
    setOtpModal(true);
  };

  const onOtpClose = () => {
    setOtpModal(false);
  };

  const submitHandler = async (e: any, formValues: any) => {
    e.preventDefault();
    dispatch<any>(SignUp(formValues, toastPositions, toast, onOtpOpen));
  };

  const handleVerifyOTP = async (e: any, formValues: any) => {
    e.preventDefault();
    dispatch<any>(SignUpVerify(formValues, toastPositions, toast));
  };

  return (
    <AuthForm
      onOtpClose={onOtpClose}
      onOtpOpen={onOtpOpen}
      handleVerifyOTP={handleVerifyOTP}
      otpModal={otpModal}
      title="Sign Up"
      description="Create an account to start your journey"
      fields={[
        { id: "username", label: "User Name", type: "text", isRequired: true },
        {
          id: "email",
          label: "Email address",
          type: "email",
          isRequired: true,
        },
        {
          id: "phone_no",
          label: "Phone Number",
          type: "number",
          isRequired: true,
        },
        {
          id: "password",
          label: "Password",
          type: "password",
          isRequired: true,
        },
        {
          id: "confirmPassword",
          label: "Confirm Password",
          type: "password",
          isRequired: true,
        },
      ]}
      submitHandler={submitHandler}
      alternateText="Already have an account?"
      alternateLink="/sign-in"
      alternateLinkText="Sign In"
    />
  );
};

export default SignUpPage;
