import React, { useState, useRef } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Td,
  useColorModeValue,
  useDisclosure,
  Grid,
  Switch,
  Badge,
} from "@chakra-ui/react";
import RoleModal from "./RoleModal";
import { formatDate, truncateWords } from "../../../../util/uitil";
import { useSelector } from "react-redux";

interface UserTablesRowProps<T> {
  data: T;
  onEdit: (editedData: T) => void;
  onDelete: (id: T) => void;
}

const UserTablesRow = <
  T extends {
    _id?: string;
    username: string;
    phone_no: string;
    email: string;
    profilePic?: string;
    role?: any;
    password?: string;
    Status?: boolean;
    verified?: boolean;
    createdAt?: string;
    updatedAt?: string;
  }
>({
  data,
  onEdit,
  onDelete,
}: UserTablesRowProps<T>) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef<HTMLInputElement | null>(null);
  const finalRef = useRef<HTMLInputElement | null>(null);
  const textColor = useColorModeValue("gray.800", "white");
  const [editingData, setEditingData] = useState<T>(data);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);

  const handleEdit = () => {
    onEdit(editingData);
    onClose();
  };

  const handleDelete = () => {
    setShowDeleteModal(false);
    onDelete(data);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;

    if (type === "checkbox") {
      const isChecked = (e.target as HTMLInputElement).checked;
      setEditingData((prevData) => ({
        ...prevData,
        [name]: isChecked,
      }));
    } else {
      setEditingData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSelectRole = (role: any) => {
    setEditingData((prevData) => ({
      ...prevData,
      role: role,
    }));
    setIsRoleModalOpen(false);
  };

  const { userInfo } = useSelector((state: { signUp: any }) => state.signUp);
  const isUser = userInfo?.userInfo?.username === data.username;

  console.log(userInfo);

  return (
    <>
      <Td
        background={isUser ? "#3c45ff30" : ""}
        minWidth={{ sm: "250px" }}
        pl="0px"
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Avatar
            src={data.profilePic ? data.profilePic : data.username}
            name={data.username}
            w="50px"
            borderRadius="full"
            me="18px"
          />
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {truncateWords(data.username, 15)}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {truncateWords(data.email, 15)}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {data.phone_no}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td background={isUser ? "#3c45ff30" : ""}>
        <Flex textAlign={"center"} direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {data?.role?.name}
          </Text>
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {data?.role?.permissions?.length} Permissions
          </Text>
        </Flex>
      </Td>
      <Td background={isUser ? "#3c45ff30" : ""}>
        <Badge
          bg={data.Status === true ? "green.400" : "red.400"}
          color={"white"}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {data.Status ? "Active" : "InActive"}
        </Badge>
      </Td>

      <Td background={isUser ? "#3c45ff30" : ""}>
        <Badge
          bg={data.verified === true ? "blue.400" : "red.400"}
          color={"white"}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {data.verified ? "Verified" : "Not Verified"}
        </Badge>
      </Td>

      <Td background={isUser ? "#3c45ff30" : ""}>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {formatDate(data.createdAt)}
        </Text>
      </Td>

      <Td background={isUser ? "#3c45ff30" : ""}>
        <Grid gridAutoFlow={"column"} gap={"10px"}>
          <Button
            onClick={() => {
              setEditingData(data);
              onOpen();
            }}
            variant="outline"
            colorScheme="purple"
            py={"2px"}
          >
            Edit
          </Button>
          <Button
            onClick={() => setShowDeleteModal(true)}
            variant="outline"
            colorScheme="red"
            py={"2px"}
            px={"30px"}
          >
            <Text fontSize="md" fontWeight="bold" cursor="pointer">
              Delete
            </Text>
          </Button>
        </Grid>
      </Td>

      {/* modal */}

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size={"xs"}
      >
        <ModalOverlay />
        <ModalContent
          backdropFilter={"blur(20px)"}
          background={useColorModeValue("whiteAlpha.500", "blackAlpha.600")}
          borderWidth={"1px"}
          borderRadius={"15px"}
        >
          <ModalHeader>
            <Flex justify="space-between">
              <Flex align="center" gap="4">
                <Avatar
                  name={editingData.username}
                  src={editingData.profilePic}
                />
                <Box>
                  <Heading size="sm">{editingData.username}</Heading>
                  <Text fontSize="sm" color="gray.400" fontWeight="normal">
                    {editingData.role?.name}
                  </Text>
                </Box>
              </Flex>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                defaultValue={editingData.username}
                onChange={handleInputChange}
                name="username"
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Email</FormLabel>
              <Input
                defaultValue={editingData.email}
                onChange={handleInputChange}
                name="email"
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Role</FormLabel>
              <Input defaultValue={editingData.role?.name} isDisabled />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Role</FormLabel>
              <Flex ml={5} alignItems={"center"} gap={10}>
                <Text>{editingData.role?.name}</Text>
                <Button
                  colorScheme="green"
                  onClick={() => setIsRoleModalOpen(true)}
                >
                  Change Role
                </Button>
              </Flex>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Status</FormLabel>
              <Switch
                colorScheme="green"
                isChecked={editingData.Status}
                onChange={handleInputChange}
                name="Status"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" mr={3} onClick={handleEdit}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <RoleModal
        isOpen={isRoleModalOpen}
        onClose={() => setIsRoleModalOpen(false)}
        onSelectRole={handleSelectRole}
      />

      {/* delete modal  */}
      <Modal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        size="sm"
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          backdropFilter={"blur(20px)"}
          background={useColorModeValue("whiteAlpha.500", "blackAlpha.600")}
          borderWidth={"1px"}
          borderRadius={"15px"}
        >
          <ModalHeader>Delete User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete this User?</Text>
          </ModalBody>
          <ModalFooter gap={"10px"}>
            <Button colorScheme="red" onClick={handleDelete}>
              Delete
            </Button>
            <Button onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserTablesRow;
