export const getConfig = (userInfo: any) => ({
  headers: {
    responseType: "blob",

    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${userInfo?.token}`,
  },
});

// export const getConfigForFileUpload = (userInfo: any) => ({
//   headers: {
//     "Content-Type": "multipart/form-data",
//     Authorization: `Bearer ${userInfo?.token}`,
//     maxBodyLength: Infinity,
//   },
// });
export const getConfigForFileUploads = (
  userInfo: any,
  onUploadProgress: (progressEvent: any) => void
) => ({
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${userInfo?.token}`,
  },
  maxBodyLength: Infinity,
  onUploadProgress,
});
