import {
  Box,
  BoxProps,
  Button,
  Code,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Image,
  Link,
  List,
  ListItem,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import { Link as RouterLink } from "react-router-dom";
import logo from "./../assets/makigifts-logo-header.png";
import { useDispatch, useSelector } from "react-redux";
import { getActiveAboutUs } from "../Redux/action/aboutus.action";
import { useEffect, useState } from "react";
import remarkGfm from "remark-gfm";
import SocialMedia from "./SocialMedia";
import ReactMarkdown from "react-markdown";
import { Loading, NoData } from "./Common/Components";
import { BsTextCenter } from "react-icons/bs";
import ErrorCard from "./Common/ErrorCard";

interface NavBarProps extends BoxProps {
  showColorModeSwitcher?: boolean;
}

const NavBar: React.FC<NavBarProps> = ({ showColorModeSwitcher, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();

  const { aboutUs, aboutUsloading, error } = useSelector(
    (state: { getActiveAboutUs: any }) => state.getActiveAboutUs
  );

  const toastPositions = useSelector((state: any) => state.toastPosition);

  useEffect(() => {
    dispatch<any>(getActiveAboutUs(toastPositions, toast));
  }, [dispatch, toast]);

  return (
    <>
      <Flex
        as="nav"
        position="absolute"
        top="0"
        left="0"
        right="0"
        justifyContent="space-between"
        alignItems="center"
        paddingX="1rem"
        paddingY="1rem"
        zIndex="1000"
        background={"linear-gradient(180deg, black 0%, transparent 81%)"}
        {...props}
      >
        <Link as={RouterLink} to="/">
          <Image src={logo} alt="maki gifts Logo" height="50px" />
        </Link>

        {/* Items on the right */}
        <Flex align={"center"} gap={"10px"}>
          <ColorModeSwitcher />

          {/* <Link colorScheme="teal" as={RouterLink} to={"/contact"}>
            Contact Us
          </Link> */}

          <Button onClick={onOpen} colorScheme="teal">
            About Us
          </Button>
        </Flex>
      </Flex>

      <Drawer isOpen={isOpen} size={"sm"} placement="bottom" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>About Us</DrawerHeader>

            <DrawerBody maxHeight={"70vh"}>
              {error && (
                <Flex
                  height={"50vh"}
                  width={"full"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box width={"200px"}>
                    <ErrorCard message={error} />
                  </Box>
                </Flex>
              )}

              {aboutUsloading && <Loading />}

              {!error && aboutUs ? (
                <ReactMarkdown
                  children={
                    typeof aboutUs.text === "string" ? aboutUs.text : ""
                  }
                  remarkPlugins={[remarkGfm]}
                  components={{
                    h1: ({ children }) => (
                      <Heading as="h1" size="xl" mt={4} mb={2}>
                        {children}
                      </Heading>
                    ),
                    h2: ({ children }) => (
                      <Heading as="h2" size="lg" mt={4} mb={2}>
                        {children}
                      </Heading>
                    ),
                    h3: ({ children }) => (
                      <Heading as="h3" size="md" mt={4} mb={2}>
                        {children}
                      </Heading>
                    ),
                    p: ({ children }) => (
                      <Text mt={2} mb={2}>
                        {children}
                      </Text>
                    ),
                    a: ({ href, children }) => (
                      <Link href={href} color="teal.500" isExternal>
                        {children}
                      </Link>
                    ),
                    ul: ({ children }) => (
                      <List styleType="disc" pl={4} mt={2} mb={2}>
                        {children}
                      </List>
                    ),
                    ol: ({ children }) => (
                      <List styleType="decimal" pl={4} mt={2} mb={2}>
                        {children}
                      </List>
                    ),
                    li: ({ children }) => <ListItem>{children}</ListItem>,

                    code: ({ className, children }) => {
                      const isInline = !className;
                      return isInline ? (
                        <Code display="inline" p={1}>
                          {children}
                        </Code>
                      ) : (
                        <Code
                          display="block"
                          whiteSpace="pre"
                          p={2}
                          my={2}
                          w="100%"
                        >
                          {children}
                        </Code>
                      );
                    },
                    img: ({ src, alt }) => (
                      <>
                        {loading && (
                          <Box
                            // position="absolute"
                            height="150px"
                            width="150px"
                          >
                            <Spinner size="xl" color="white" />
                          </Box>
                        )}

                        <Image
                          onLoad={() => setLoading(false)}
                          display={loading ? "none" : "block"}
                          src={src}
                          alt={alt}
                          my={4}
                        />
                      </>
                    ),
                  }}
                />
              ) : (
                !error && <NoData icon={BsTextCenter} />
              )}

              {/* social m */}
              <SocialMedia my={"30px"} />
            </DrawerBody>

            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Close
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};

export default NavBar;
