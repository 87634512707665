import {
  CHANGE_PERSONAL_ACCOUNT_PASSWORD_FAIL,
  CHANGE_PERSONAL_ACCOUNT_PASSWORD_REQUEST,
  CHANGE_PERSONAL_ACCOUNT_PASSWORD_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DISABLE_USERS_FAILURE,
  DISABLE_USERS_REQUEST,
  DISABLE_USERS_SUCCESS,
  EDIT_PERSONAL_ACCOUNT_FAIL,
  EDIT_PERSONAL_ACCOUNT_REQUEST,
  EDIT_PERSONAL_ACCOUNT_SUCCESS,
  ENABLE_USERS_FAILURE,
  ENABLE_USERS_REQUEST,
  ENABLE_USERS_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  GET_ALL_USERS_FAIL,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_LOGGED_IN_USER_FAIL,
  GET_LOGGED_IN_USER_REQUEST,
  GET_LOGGED_IN_USER_SUCCESS,
  GET_USER_BY_ID_FAIL,
  GET_USER_BY_ID_REQUEST,
  GET_USER_BY_ID_SUCCESS,
  RESET_ALL_STATES,
  RESET_GET_LOGGED_IN_USER_STATE,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  UPDATE_USER_ADMIN_FAIL,
  UPDATE_USER_ADMIN_REQUEST,
  UPDATE_USER_ADMIN_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  USER_LOGOUT,
  USER_SIGNIN_FAIL,
  USER_SIGNIN_OTP_FAIL,
  USER_SIGNIN_OTP_REQUEST,
  USER_SIGNIN_OTP_SUCCESS,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNUP_FAIL,
  USER_SIGNUP_OTP_FAIL,
  USER_SIGNUP_OTP_REQUEST,
  USER_SIGNUP_OTP_SUCCESS,
  USER_SIGNUP_REQUEST,
  USER_SIGNUP_SUCCESS,
} from "../constant/user.constant";

export const SignUpInitialState = {
  loading: false,
  userInfo: null,
  message: null,
  error: null,
};

export const signUpReducer = (
  state = SignUpInitialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case USER_SIGNUP_OTP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case USER_SIGNUP_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
        error: null,
      };
    case USER_SIGNUP_OTP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_ALL_STATES:
      return {
        ...state,
        loading: false,
        userInfo: null,
        error: null,
      };
    default:
      return state;
  }
};

export const signUpVerifyReducer = (
  state = SignUpInitialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case USER_SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case USER_SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
        error: null,
      };
    case USER_SIGNUP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_ALL_STATES:
      return {
        ...state,
        loading: false,
        userInfo: null,
        error: null,
      };
    default:
      return state;
  }
};

export const signInReducer = (
  state = SignUpInitialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case USER_SIGNIN_OTP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case USER_SIGNIN_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
        error: null,
      };
    case USER_SIGNIN_OTP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RESET_ALL_STATES:
      return SignUpInitialState;

    default:
      return state;
  }
};

export const signInVerifyReducer = (
  state = SignUpInitialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case USER_SIGNIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case USER_SIGNIN_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
        error: null,
      };
    case USER_SIGNIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RESET_ALL_STATES:
      return SignUpInitialState;

    default:
      return state;
  }
};

export const forgetPasswordReducer = (
  state = SignUpInitialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
        error: null,
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RESET_ALL_STATES:
      return SignUpInitialState;

    default:
      return state;
  }
};

export const resetPasswordReducer = (
  state = SignUpInitialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
        error: null,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RESET_ALL_STATES:
      return SignUpInitialState;

    default:
      return state;
  }
};

export const userSignOutReducer = (
  state = SignUpInitialState,
  action: { type: any }
) => {
  switch (action.type) {
    case USER_LOGOUT:
      return {
        ...state,
        signUp: {
          userInfo: null,
          loading: false,
          error: null,
        },
      };

    case RESET_ALL_STATES:
      return SignUpInitialState;

    default:
      return state;
  }
};

// /////////////////

const initialState = {
  loading: false,
  users: [],
  userInfo: null,
  user: null,
  error: null,
};

export const getAllUsersReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_ALL_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
        error: null,
      };
    case GET_ALL_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getUserByIdReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_USER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
      };
    case GET_USER_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getLoggedInUserReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_LOGGED_IN_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_LOGGED_IN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
        error: null,
      };
    case GET_LOGGED_IN_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RESET_GET_LOGGED_IN_USER_STATE:
      return initialState;

    default:
      return state;
  }
};

export const editPersonalAccountReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case EDIT_PERSONAL_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EDIT_PERSONAL_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
        error: null,
      };
    case EDIT_PERSONAL_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const changePersonalAccountPasswordReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case CHANGE_PERSONAL_ACCOUNT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CHANGE_PERSONAL_ACCOUNT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
        error: null,
      };
    case CHANGE_PERSONAL_ACCOUNT_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updateUserReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
        error: null,
      };
    case UPDATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updateUserAdminReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case UPDATE_USER_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_USER_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
      };
    case UPDATE_USER_ADMIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteUserReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
      };
    case DELETE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const disableAllUserReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case DISABLE_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DISABLE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
      };
    case DISABLE_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const enableAllUserReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case ENABLE_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ENABLE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
      };
    case ENABLE_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
