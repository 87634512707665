import { SET_TOAST_POSITION } from "../constant/setting.constant";

export const setToastPositionAction =
  (position: any) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    dispatch({
      type: SET_TOAST_POSITION,
      payload: position,
    });
  };
