import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AuthForm from "../components/AuthForm";
import { forgetPassword, resetPassword } from "../Redux/action/user.action";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "../interceptor/useDocumentTitle";

const ForgetPassword = () => {
  useDocumentTitle("Forget Password");
  const toastPositions = useSelector((state: any) => state.toastPosition);
  const [otpModal, setOtpModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const onOtpOpen = () => {
    setOtpModal(true);
  };

  const onOtpClose = () => {
    setOtpModal(false);
  };

  const submitHandler = async (e: any, formValues: any) => {
    e.preventDefault();
    dispatch<any>(forgetPassword(formValues, toastPositions, toast, onOtpOpen));
  };

  const handleVerifyOTP = async (e: any, formValues: any) => {
    e.preventDefault();
    dispatch<any>(
      resetPassword(formValues, toastPositions, toast, navigate, onOtpClose)
    );
  };

  return (
    <>
      <AuthForm
        onOtpClose={onOtpClose}
        onOtpOpen={onOtpOpen}
        otpModal={otpModal}
        handleVerifyOTP={handleVerifyOTP}
        title="Reset Password"
        description="Enter your email address to receive a password reset OTP."
        fields={[
          {
            id: "email",
            label: "Email address",
            type: "email",
            isRequired: true,
          },
        ]}
        submitHandler={submitHandler}
        alternateText="Back to sign in"
        alternateLink="/sign-in"
        alternateLinkText="Sign-In"
      />
    </>
  );
};

export default ForgetPassword;
