import { applyMiddleware, combineReducers, createStore } from "redux";
import { thunk } from "redux-thunk";
import {
  changePersonalAccountPasswordReducer,
  deleteUserReducer,
  disableAllUserReducer,
  editPersonalAccountReducer,
  enableAllUserReducer,
  forgetPasswordReducer,
  getAllUsersReducer,
  getLoggedInUserReducer,
  getUserByIdReducer,
  resetPasswordReducer,
  signInReducer,
  signInVerifyReducer,
  signUpReducer,
  signUpVerifyReducer,
  updateUserAdminReducer,
  updateUserReducer,
  userSignOutReducer,
} from "./reducer/user.reducer";
import { toastPositionReducer } from "./reducer/setting.reducer";
import {
  getActiveShareVideoByIdReducer,
  getAllShareVideosReducer,
  getShareVideoByIdReducer,
  createShareVideoReducer,
  updateShareVideoReducer,
  deleteShareVideoReducer,
} from "./reducer/video.reducer";
import {
  getActiveSharePictureReducer,
  getAllSharePicturesReducer,
  getSharePictureByIdReducer,
  createSharePictureReducer,
  updateSharePictureReducer,
  deleteSharePictureReducer,
} from "./reducer/picture.reducer";
import {
  getAllActiveSocialLinksReducer,
  getAllSocialLinksReducer,
  getSocialLinksByIdReducer,
  createSocialLinksReducer,
  updateSocialLinksReducer,
  deleteSocialLinksReducer,
} from "./reducer/socialLinks.reducer";
import {
  getActiveShareTextReducer,
  getAllShareTextsReducer,
  getShareTextByIdReducer,
  createShareTextReducer,
  updateShareTextReducer,
  deleteShareTextReducer,
} from "./reducer/text.reducer";
import {
  getAboutUsReducer,
  getActiveAboutUsReducer,
  createAboutUsReducer,
  updateAboutUsReducer,
  deleteAboutUsReducer,
} from "./reducer/aboutus.reducer";
import {
  getAllLandingReducer,
  getAllActiveLandingReducer,
  getLandingByIdReducer,
  createLandingReducer,
  updateLandingReducer,
  deleteLandingReducer,
} from "./reducer/landing.reducer";
import {
  getAllPermissionsReducer,
  getActivePermissionsReducer,
  getPermissionByIdReducer,
  createPermissionReducer,
  updatePermissionReducer,
  deletePermissionReducer,
} from "./reducer/permission.reducer";
import {
  getAllRolesReducer,
  getRoleByIdReducer,
  createRoleReducer,
  updateRoleReducer,
  deleteRoleReducer,
} from "./reducer/role.reducer";

const reducer = combineReducers({
  signUp: signUpVerifyReducer,
  signUpVerify: signUpReducer,
  signIn: signInVerifyReducer,
  signInVerify: signInReducer,
  userSignOut: userSignOutReducer,
  forgetPassword: forgetPasswordReducer,
  resetPassword: resetPasswordReducer,
  toastPosition: toastPositionReducer,

  allUsers: getAllUsersReducer,
  userById: getUserByIdReducer,
  loggedInUser: getLoggedInUserReducer,
  personalAccount: editPersonalAccountReducer,
  personalAccountPassword: changePersonalAccountPasswordReducer,
  updateUser: updateUserReducer,
  updateUserAdmin: updateUserAdminReducer,
  deleteUser: deleteUserReducer,
  disableAllUser: disableAllUserReducer,
  enableAllUser: enableAllUserReducer,

  //   share video
  activeShareVideoById: getActiveShareVideoByIdReducer,
  allShareVideos: getAllShareVideosReducer,
  shareVideoById: getShareVideoByIdReducer,
  createShareVideo: createShareVideoReducer,
  updateShareVideo: updateShareVideoReducer,
  deleteShareVideo: deleteShareVideoReducer,

  //   share pic
  activeSharePicture: getActiveSharePictureReducer,
  allSharePictures: getAllSharePicturesReducer,
  sharePictureById: getSharePictureByIdReducer,
  createSharePicture: createSharePictureReducer,
  updateSharePicture: updateSharePictureReducer,
  deleteSharePicture: deleteSharePictureReducer,

  //   share text
  activeShareText: getActiveShareTextReducer,
  allShareTexts: getAllShareTextsReducer,
  shareTextById: getShareTextByIdReducer,
  createShareText: createShareTextReducer,
  updateShareText: updateShareTextReducer,
  deleteShareText: deleteShareTextReducer,

  //   social links
  allActiveSocialLinks: getAllActiveSocialLinksReducer,
  allSocialLinks: getAllSocialLinksReducer,
  socialLinksById: getSocialLinksByIdReducer,
  createSocialLinks: createSocialLinksReducer,
  updateSocialLinks: updateSocialLinksReducer,
  deleteSocialLinks: deleteSocialLinksReducer,

  //   landing
  getAllLanding: getAllLandingReducer,
  getAllActiveLanding: getAllActiveLandingReducer,
  getLandingById: getLandingByIdReducer,
  createLanding: createLandingReducer,
  updateLanding: updateLandingReducer,
  deleteLanding: deleteLandingReducer,

  // about
  getAboutUs: getAboutUsReducer,
  getActiveAboutUs: getActiveAboutUsReducer,
  createAboutUs: createAboutUsReducer,
  updateAboutUs: updateAboutUsReducer,
  deleteAboutUs: deleteAboutUsReducer,

  // permission
  getAllPermissions: getAllPermissionsReducer,
  getActivePermissions: getActivePermissionsReducer,
  getPermissionById: getPermissionByIdReducer,
  createPermission: createPermissionReducer,
  updatePermission: updatePermissionReducer,
  deletePermission: deletePermissionReducer,

  // roles
  getAllRoles: getAllRolesReducer,
  getRoleById: getRoleByIdReducer,
  createRole: createRoleReducer,
  updateRole: updateRoleReducer,
  deleteRole: deleteRoleReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo");
const parsedUserInfo: string | null = userInfoFromStorage
  ? JSON.parse(userInfoFromStorage)
  : null;

const initialState: {
  signUp: any;
  signIn?: any;
  toastPosition?: any;
} = {
  signUp: {
    userInfo: parsedUserInfo ? parsedUserInfo : null,
    loading: false,
    error: null,
  },
  signIn: {
    userInfo: parsedUserInfo ? parsedUserInfo : null,
    loading: false,
    error: null,
  },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  // composeWithDevTools(
  applyMiddleware(...middleware)
  // )
);

export type DispatchType = typeof store.dispatch;

export default store;
