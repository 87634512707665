import { Box, Center, CircularProgress, Heading, Text } from "@chakra-ui/react";
import { IconType } from "react-icons";

export function Loading() {
  return (
    <div
      style={{
        display: "grid",
        justifyItems: "center",
        alignContent: "center",
        height: "50vh",
      }}
    >
      <CircularProgress isIndeterminate color={"teal.400"} />
      <Text fontSize="md" color="gray.500">
        Loading . ..
      </Text>
    </div>
  );
}

interface NoDataProps {
  icon: IconType;
}

export function NoData({ icon: Icon }: NoDataProps) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" h="400px">
      <Box textAlign="center">
        <Center color="teal.300">
          <Icon color="teal.300" fontSize="100px" />
        </Center>
        <Heading as="h3" size="lg" mb={2}>
          No data available
        </Heading>
        <Text fontSize="md" color="gray.500">
          There is currently no data available for display.
        </Text>
      </Box>
    </Box>
  );
}
