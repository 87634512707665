import { UseToastOptions } from "@chakra-ui/react";
import axios from "axios";
import { handleErrorMessage } from "../../interceptor/ErrorHandler";
import { User } from "../../interface/user.interface";
import API_BASE_URL from "../../util/config";
import {
  USER_SIGNUP_REQUEST,
  USER_SIGNUP_SUCCESS,
  USER_SIGNUP_FAIL,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_FAIL,
  SET_USER_ROLE,
  USER_LOGOUT,
  RESET_ALL_STATES,
  CHANGE_PERSONAL_ACCOUNT_PASSWORD_FAIL,
  CHANGE_PERSONAL_ACCOUNT_PASSWORD_REQUEST,
  CHANGE_PERSONAL_ACCOUNT_PASSWORD_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  EDIT_PERSONAL_ACCOUNT_FAIL,
  EDIT_PERSONAL_ACCOUNT_REQUEST,
  EDIT_PERSONAL_ACCOUNT_SUCCESS,
  GET_ALL_USERS_FAIL,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_LOGGED_IN_USER_FAIL,
  GET_LOGGED_IN_USER_REQUEST,
  GET_LOGGED_IN_USER_SUCCESS,
  GET_USER_BY_ID_FAIL,
  GET_USER_BY_ID_REQUEST,
  GET_USER_BY_ID_SUCCESS,
  UPDATE_USER_ADMIN_FAIL,
  UPDATE_USER_ADMIN_REQUEST,
  UPDATE_USER_ADMIN_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  ENABLE_USERS_REQUEST,
  ENABLE_USERS_SUCCESS,
  ENABLE_USERS_FAILURE,
  DISABLE_USERS_REQUEST,
  DISABLE_USERS_SUCCESS,
  DISABLE_USERS_FAILURE,
  RESET_GET_LOGGED_IN_USER_STATE,
  USER_SIGNIN_OTP_REQUEST,
  USER_SIGNIN_OTP_SUCCESS,
  USER_SIGNIN_OTP_FAIL,
  USER_SIGNUP_OTP_SUCCESS,
  USER_SIGNUP_OTP_FAIL,
  USER_SIGNUP_OTP_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from "../constant/user.constant";
import { SET_TOAST_POSITION } from "../constant/setting.constant";
import { getConfig } from "./common/action";

export const SignUp =
  (
    formValues: User,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void,
    openModal: () => void
  ) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      if (!formValues.password || !formValues.email || !formValues.username) {
        toasts({
          title: "Invalid Input.",
          status: "error",
          duration: 3000,
          position: toastPosition ? toastPosition : "top-right",
          isClosable: true,
        });
        return;
      }

      dispatch({ type: USER_SIGNUP_OTP_REQUEST });

      //   let generatedProfilePic = generate(username, "white", color);

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${API_BASE_URL}signup`,
        formValues,
        config
      );
      openModal();
      toasts({
        title: data?.message,
        status: "success",
        duration: 5000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });

      dispatch({ type: USER_SIGNUP_OTP_SUCCESS, payload: data?.message });
    } catch (error: any) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        USER_SIGNUP_OTP_FAIL
      );
    }
  };

export const SignUpVerify =
  (
    formValues: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      if (
        !formValues.password ||
        !formValues.email ||
        !formValues.username ||
        formValues.otpCode?.length !== 6
      ) {
        toasts({
          title: "Invalid Input.",
          status: "error",
          duration: 3000,
          position: toastPosition ? toastPosition : "top-right",
          isClosable: true,
        });
        return;
      }

      dispatch({ type: USER_SIGNUP_REQUEST });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${API_BASE_URL}signup/verify`,
        formValues,
        config
      );

      let userInfo = data?.UserInfo;
      let userRole = data?.Role;

      toasts({
        title: "You Have Successfully Registered!",
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });

      dispatch(setUserRole(userRole));
      dispatch({ type: USER_SIGNIN_SUCCESS, payload: userInfo });
      dispatch({ type: USER_SIGNUP_SUCCESS, payload: userInfo });
      dispatch({ type: SET_TOAST_POSITION, payload: "top-right" });
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    } catch (error: any) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        USER_SIGNUP_FAIL
      );
    }
  };

export const SignInAction =
  (
    { email, password }: User,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void,
    openModal: () => void
  ) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      if (!password || !email) {
        toasts({
          title: "Invalid Email or Password!",
          status: "error",
          duration: 3000,
          position: toastPosition ? toastPosition : "top-right",
          isClosable: true,
        });
        return;
      }

      dispatch({ type: USER_SIGNIN_OTP_REQUEST });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${API_BASE_URL}signin`,
        { email, password },
        config
      );

       openModal();
      toasts({
        title: data?.message,
        status: "success",
        duration: 5000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });

      dispatch({ type: USER_SIGNIN_OTP_SUCCESS, payload: data?.message });
 
    } catch (error: any) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        USER_SIGNIN_OTP_FAIL
      );
     
    }
  };

export const setUserRole = (role: any) => ({
  type: SET_USER_ROLE,
  payload: role,
});

// Sign in
export const signInVerify =
  (
    { email, password, otpCode }: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
     try {
      if (!password || !email || otpCode?.length !== 6) {
        toasts({
          title: "Invalid Email , OTP or Password!",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      dispatch({ type: USER_SIGNIN_REQUEST });
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${API_BASE_URL}signin/verify`,
        { email, password, otpCode },
        config
      );
 
      let userInfo = data?.UserInfo;
      let userRole = data?.Role;

      toasts({
        title: `Welcome Back ${userInfo.userInfo.username}`,
        status: "success",
        duration: 5000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });

 
      dispatch(setUserRole(userRole));
      dispatch({ type: USER_SIGNIN_SUCCESS, payload: userInfo });
      dispatch({ type: USER_SIGNUP_SUCCESS, payload: userInfo });
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    } catch (error: any) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        USER_SIGNIN_FAIL
      );
    }
  };

// forget pass
export const forgetPassword =
  (
    { email }: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void,
    openModal: () => void
  ) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      if (!email) {
        toasts({
          title: "Invalid Email!",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      dispatch({ type: FORGOT_PASSWORD_REQUEST });
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${API_BASE_URL}forgot-password`,
        { email },
        config
      );

      openModal();
      toasts({
        title: data?.message,
        status: "success",
        duration: 5000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });

      dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: data?.message });
    } catch (error: any) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        FORGOT_PASSWORD_FAILURE
      );
    }
  };

export const resetPassword =
  (
    { email, otpCode, newPassword }: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void,
    navigate: any,
    closeModal: () => void
  ) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      if (!email || !newPassword || otpCode?.length !== 6) {
        toasts({
          title: "Invalid Inputs!",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      dispatch({ type: RESET_PASSWORD_REQUEST });
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${API_BASE_URL}reset-password`,
        { email, otpCode, newPassword },
        config
      );

      navigate("/sign-in");
      toasts({
        title: data?.message,
        status: "success",
        duration: 5000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });

      dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data?.message });
    } catch (error: any) {
       const resetError = error.response?.data?.message;
      if (
        resetError === "OTP expired try again" ||
        resetError === "No user found with this email address"
      ) {
        closeModal();
      }
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        RESET_PASSWORD_FAILURE
      );
    }
  };

export const signOut =
  (
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: any; payload?: any }) => void,
    getState: any
  ) => {
    localStorage.removeItem("userInfo");

    dispatch({ type: USER_LOGOUT });
    dispatch({ type: RESET_ALL_STATES });

    toasts({
      title: `Signed Out Successfully`,
      status: "success",
      duration: 3000,
      position: toastPosition ? toastPosition : "top-right",
      isClosable: true,
    });
  };

// Create a resetAllStates action creator
export const resetAllStates = () => ({ type: RESET_ALL_STATES });

// ///////////////

export const getAllUsers =
  (
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_ALL_USERS_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.get(`${API_BASE_URL}users`, config);

      dispatch({
        type: GET_ALL_USERS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_ALL_USERS_FAIL
      );
    }
  };

export const getUserById =
  (
    userId: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_USER_BY_ID_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.get(
        `${API_BASE_URL}users/${userId}`,
        config
      );

      dispatch({
        type: GET_USER_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_USER_BY_ID_FAIL
      );
    }
  };
export const getLoggedInUser =
  (
    password: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_LOGGED_IN_USER_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.post(
        `${API_BASE_URL}me`,
        { password },
        config
      );

      dispatch({
        type: GET_LOGGED_IN_USER_SUCCESS,
        payload: data.userInfo,
      });

      // Add toast notification
      toasts({
        title: `Logged in user data retrieved successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_LOGGED_IN_USER_FAIL
      );
    }
  };

export const editPersonalAccount =
  (
    userData: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: EDIT_PERSONAL_ACCOUNT_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.put(`${API_BASE_URL}me`, userData, config);

      dispatch({
        type: EDIT_PERSONAL_ACCOUNT_SUCCESS,
        payload: data,
      });

      // Add toast notification
      toasts({
        title: `Personal account edited successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
      // localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        EDIT_PERSONAL_ACCOUNT_FAIL
      );
    }
  };

export const changePersonalAccountPassword =
  (
    passwords: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: CHANGE_PERSONAL_ACCOUNT_PASSWORD_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.put(
        `${API_BASE_URL}me/password`,
        passwords,
        config
      );

      dispatch({
        type: CHANGE_PERSONAL_ACCOUNT_PASSWORD_SUCCESS,
        payload: data,
      });

      // Add toast notification
      toasts({
        title: `Password changed successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
      dispatch(resetGetLoggedInUserState());
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        CHANGE_PERSONAL_ACCOUNT_PASSWORD_FAIL
      );
    }
  };

export const updateUser =
  (
    userId: any,
    userData: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: UPDATE_USER_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.put(
        `${API_BASE_URL}users/${userId}`,
        userData,
        config
      );

      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: data,
      });

      // Add toast notification
      toasts({
        title: `User updated successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        UPDATE_USER_FAIL
      );
    }
  };

export const updateUserAdmin =
  (
    userId: any,
    userData: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: UPDATE_USER_ADMIN_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.put(
        `${API_BASE_URL}users/admin/${userId}`,
        userData,
        config
      );

      dispatch({
        type: UPDATE_USER_ADMIN_SUCCESS,
        payload: data,
      });

      // Add toast notification
      toasts({
        title: `Admin user updated successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        UPDATE_USER_ADMIN_FAIL
      );
    }
  };

export const deleteUser =
  (
    userId: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: DELETE_USER_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.delete(
        `${API_BASE_URL}users/${userId}`,
        config
      );

      dispatch({
        type: DELETE_USER_SUCCESS,
        payload: data,
      });

      // Add toast notification
      toasts({
        title: `User deleted successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        DELETE_USER_FAIL
      );
    }
  };

export const enableAllUser =
  (
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: ENABLE_USERS_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.patch(
        `${API_BASE_URL}users/enableAll`,
        config
      );

      dispatch({
        type: ENABLE_USERS_SUCCESS,
        payload: data,
      });

      // Add toast notification
      toasts({
        title: data?.message,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        ENABLE_USERS_FAILURE
      );
    }
  };

export const disableAllUser =
  (
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: DISABLE_USERS_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.patch(
        `${API_BASE_URL}users/disableAllExceptLoggedIn`,
        config
      );

      dispatch({
        type: DISABLE_USERS_SUCCESS,
        payload: data,
      });

      // Add toast notification
      toasts({
        title: data?.message,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        DISABLE_USERS_FAILURE
      );
    }
  };

export const resetGetLoggedInUserState = () => ({
  type: RESET_GET_LOGGED_IN_USER_STATE,
});
