import { Badge, Box, IconButton, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { truncateWords } from "../../../util/uitil";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { BiCameraOff, BiImage } from "react-icons/bi";

interface LandingCardProps {
  landing: any;
  onEdit: (id: string) => void;
  onDelete: (landing: any) => void;
}

const LandingCard: React.FC<LandingCardProps> = ({
  landing,
  onDelete,
  onEdit,
}) => {
  return (
    <Box
      backgroundSize={"cover !important"}
      background={
        landing.media
          ? `linear-gradient(360deg, rgb(0 0 0 ), rgba(0, 0, 0, 0) 100%) ,center url(${landing.media})`
          : "gray"
      }
      minWidth="200px"
      minHeight="300px"
      position="relative"
      p={4}
      borderRadius="lg"
      boxShadow="lg"
    >
      <Badge
        backgroundColor={landing.status ? "#4fd1c5" : "#d14f70"}
        position={"absolute"}
        right={"10px"}
        top={"10px"}
        zIndex={1}
        borderRadius="full"
        color={"black"}
        px="2"
      >
        {landing.status ? "Active" : "Deactive"}
      </Badge>

      <Box position="absolute" top="10px" left="10px" zIndex={1} color="white">
        {landing.media ? <BiImage size="24px" /> : <BiCameraOff size="24px" />}
      </Box>

      <Stack
        direction="column"
        spacing={2}
        position="absolute"
        bottom="30px"
        right="13px"
        zIndex={1}
      >
        <IconButton
          icon={<EditIcon />}
          aria-label="Edit"
          variant="solid"
          colorScheme="blue"
          size="sm"
          onClick={() => onEdit(landing._id)}
        />
        <IconButton
          icon={<DeleteIcon />}
          aria-label="Delete"
          variant="solid"
          colorScheme="red"
          size="sm"
          onClick={() => onDelete(landing)}
        />
      </Stack>

      <Box position="absolute" bottom={4} left={1} right={4} p={3}>
        <Text color="gray.300" fontSize="sm" fontWeight="bold" mb={0}>
          {truncateWords(landing.subTitle, 15)}
        </Text>
        <Text fontSize="xl" fontWeight="bold" color="white" mt={0} mb={1}>
          {truncateWords(landing.title, 15)}
        </Text>
        <Text color="gray.300" fontSize="md" noOfLines={2}>
          {truncateWords(landing.details, 50)}
        </Text>
      </Box>
    </Box>
  );
};

export default LandingCard;
