import axios from "axios";
import {
  GET_ACTIVE_SHARE_VIDEO_BY_ID_REQUEST,
  GET_ACTIVE_SHARE_VIDEO_BY_ID_SUCCESS,
  GET_ACTIVE_SHARE_VIDEO_BY_ID_FAIL,
  GET_ALL_SHARE_VIDEOS_REQUEST,
  GET_ALL_SHARE_VIDEOS_SUCCESS,
  GET_ALL_SHARE_VIDEOS_FAIL,
  GET_SHARE_VIDEO_BY_ID_REQUEST,
  GET_SHARE_VIDEO_BY_ID_SUCCESS,
  GET_SHARE_VIDEO_BY_ID_FAIL,
  CREATE_SHARE_VIDEO_REQUEST,
  CREATE_SHARE_VIDEO_SUCCESS,
  CREATE_SHARE_VIDEO_FAIL,
  UPDATE_SHARE_VIDEO_REQUEST,
  UPDATE_SHARE_VIDEO_SUCCESS,
  UPDATE_SHARE_VIDEO_FAIL,
  DELETE_SHARE_VIDEO_REQUEST,
  DELETE_SHARE_VIDEO_SUCCESS,
  DELETE_SHARE_VIDEO_FAIL,
} from "./../constant/video.constant";
import API_BASE_URL from "../../util/config";
import { handleErrorMessage } from "../../interceptor/ErrorHandler";
import { UseToastOptions } from "@chakra-ui/react";
import {
  getConfig,
   getConfigForFileUploads,
} from "./common/action";

export const getActiveShareVideoById =
  (
    id: string,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_ACTIVE_SHARE_VIDEO_BY_ID_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.get(`${API_BASE_URL}share/v/${id}`, config);

      dispatch({
        type: GET_ACTIVE_SHARE_VIDEO_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_ACTIVE_SHARE_VIDEO_BY_ID_FAIL
      );
    }
  };

export const getAllShareVideos =
  (
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_ALL_SHARE_VIDEOS_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

 
      const { data } = await axios.get(`${API_BASE_URL}shareVideos`, config);

      dispatch({
        type: GET_ALL_SHARE_VIDEOS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_ALL_SHARE_VIDEOS_FAIL
      );
    }
  };

export const getShareVideoById =
  (
    id: string,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_SHARE_VIDEO_BY_ID_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.get(
        `${API_BASE_URL}shareVideos/${id}`,
        config
      );

      dispatch({
        type: GET_SHARE_VIDEO_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_SHARE_VIDEO_BY_ID_FAIL
      );
    }
  };
export const createShareVideo =
  ( 
    navigate: any,
    formData: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void,
    onUploadProgress: (progressEvent: any) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: CREATE_SHARE_VIDEO_REQUEST });

   
      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfigForFileUploads(userInfo, onUploadProgress);

      const { data } = await axios.post(
        `${API_BASE_URL}shareVideos`,
        formData,
        config
      );

      toasts({
        title: `Share Video created successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });

      dispatch({
        type: CREATE_SHARE_VIDEO_SUCCESS,
        payload: data,
      });

      navigate("/v1/video");
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        CREATE_SHARE_VIDEO_FAIL
      );
    }
  };

export const updateShareVideo =
  (
    id: string, 
    navigate: any,
    formData: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void,
    onUploadProgress: (progressEvent: any) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: UPDATE_SHARE_VIDEO_REQUEST });
 

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfigForFileUploads(userInfo, onUploadProgress);

      const { data } = await axios.put(
        `${API_BASE_URL}shareVideos/${id}`,
        formData,
        config
      );

      toasts({
        title: `Share Video updated successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });

      dispatch({
        type: UPDATE_SHARE_VIDEO_SUCCESS,
        payload: data,
      });

      navigate("/v1/video");
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        UPDATE_SHARE_VIDEO_FAIL
      );
    }
  };

export const deleteShareVideo =
  (
    id: string,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: DELETE_SHARE_VIDEO_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.delete(
        `${API_BASE_URL}shareVideos/${id}`,
        config
      );

      toasts({
        title: `Share Video deleted successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });

      dispatch({
        type: DELETE_SHARE_VIDEO_SUCCESS,
        payload: data,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        DELETE_SHARE_VIDEO_FAIL
      );
    }
  };
