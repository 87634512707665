import {
  Box,
  Button,
  Center,
  Grid,
  Heading,
  HStack,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import WelcomeMessage from "../../components/WelcomeMessage";
import {
  FaUser,
  FaCogs,
  FaVideo,
  FaPhotoVideo,
  FaTextHeight,
  FaUsers,
  FaInfoCircle,
  FaLink,
} from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactNode } from "react";
import { FiMonitor } from "react-icons/fi";
import useDocumentTitle from "../../interceptor/useDocumentTitle";

const Dashboard = () => {
  useDocumentTitle("Dashboard");
  const links = [
    {
      title: "Videos",
      description: "Manage shared videos",
      icon: <FaVideo color={"#319795"} size={100} />,
      buttonText: "Manage Videos",
      link: "/v1/video",
    },
    {
      title: "Picture",
      description: "Manage shared pictures.",
      icon: <FaPhotoVideo color={"#319795"} size={100} />,
      buttonText: "Manage Pictures",
      link: "/v1/picture",
    },
    {
      title: "Text",
      description: "Manage shared text.",
      icon: <FaTextHeight color={"#319795"} size={100} />,
      buttonText: "Manage Text",
      link: "/v1/text",
    },
    {
      title: "Landing",
      description: "Manage landing page settings.",
      icon: <FiMonitor color={"#319795"} size={100} />,
      buttonText: "Manage Landing",
      link: "/v1/landing",
    },
    {
      title: "About Us",
      description: "Manage about us page settings.",
      icon: <FaInfoCircle color={"#319795"} size={100} />,
      buttonText: "Manage About Us",
      link: "/v1/about-us",
    },
    {
      title: "Social Links",
      description: "Manage social links settings.",
      icon: <FaLink color={"#319795"} size={100} />,
      buttonText: "Manage Social Links",
      link: "/v1/social",
    },
    {
      title: "Users",
      description: "Manage user settings.",
      icon: <FaUsers color={"#319795"} size={100} />,
      buttonText: "Manage Users",
      link: "/v1/users",
    },
    {
      title: "Profile",
      description: "Manage My profile settings.",
      icon: <FaUser color={"#319795"} size={100} />,
      buttonText: "Manage Profile",
      link: "/v1/profile",
    },
    {
      title: "Settings",
      description: "Manage application settings.",
      icon: <FaCogs color={"#319795"} size={100} />,
      buttonText: "Manage Settings",
      link: "/v1/settings",
    },
  ];

  const signUp = useSelector((state: { signUp: any }) => state.signUp);
  const { loading, error, userInfo } = signUp;

  return (
    <Box p={5}>
      <WelcomeMessage
        isAdmin={userInfo?.userInfo?.role?.name === "Admin"}
        userInfo={userInfo?.userInfo}
      />

      <Box py={{ base: "6", md: "8", lg: "12" }}>
        <Heading as="h1" fontSize="45px" mb={2}>
          Go To
        </Heading>
        <Text fontSize="md" color="gray.500" mb={9}>
          Choose link
        </Text>

        <Box maxW="7xl" mx="auto" px={{ base: "4", md: "8", lg: "12" }}>
          <Grid
            templateColumns={{
              base: "1fr",
              md: "repeat(auto-fill, minmax(200px, 1fr))",
            }}
            gap="16"
          >
            {links.map((link, index) => (
              <GoToCard
                icon={link.icon}
                title={link.title}
                description={link.description}
                buttonText={link.buttonText}
                link={link.link}
                key={index}
              />
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;

interface GoToCardProps {
  icon: ReactNode; // You can specify the type of the icon component as needed
  title: string;
  description: string;
  buttonText: string;
  link: string;
}

const GoToCard: React.FC<GoToCardProps> = ({
  icon,
  title,
  description,
  buttonText,
  link,
}) => {
  return (
    <Stack spacing={{ base: "4", md: "5" }}>
      <Box pos={"relative"}>
        <Center fontSize="4xl" color="blue.500" mb={4}>
          {icon}
        </Center>
      </Box>
      <Stack>
        <Stack spacing="1">
          <Text
            fontWeight="medium"
            color={useColorModeValue("gray.700", "gray.400")}
          >
            {title}
          </Text>
        </Stack>
        <HStack>
          <Text fontSize="sm" color={useColorModeValue("gray.600", "gray.400")}>
            {description}
          </Text>
        </HStack>
      </Stack>
      <Stack align="center">
        <Button as={RouterLink} to={link} colorScheme="teal" width="full">
          {buttonText}
        </Button>
      </Stack>
    </Stack>
  );
};
