import {
  Avatar,
  Box,
  Flex,
  Skeleton,
  SkeletonCircle,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { GoVerified } from "react-icons/go";
import { EmailIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import { SiStatuspage } from "react-icons/si";
import { MdDateRange } from "react-icons/md";

function OverView2() {
  const loggedInUser = useSelector(
    (state: { loggedInUser: any }) => state.loggedInUser
  );
  const { loading, error, userInfo } = loggedInUser;

  const dateOptions: any = { month: "long", day: "numeric", year: "numeric" };
  const joinedOn = new Date(userInfo?.createdAt).toLocaleDateString(
    "en-US",
    dateOptions
  );
  const UpdatedAt = new Date(userInfo?.accountUpdatedAt).toLocaleDateString(
    "en-US",
    dateOptions
  );

  return (
    <Box
      boxShadow={"0 0 12px -10px"}
      background={useColorModeValue("white", "gray.800")}
      borderRadius={"15px"}
      padding={"2rem"}
    >
      <Stack
        justifyContent={"space-evenly"}
        flexDirection={{ base: "column", md: "row" }}
      >
        {loading ? (
          <SkeletonCircle size="36" />
        ) : (
          <Avatar
            marginTop={"0 !important"}
            minWidth={"170px"}
            fontSize={"40px"}
            minHeight={"170px"}
            name={userInfo ? userInfo?.username : "Dan Abrahmov"}
            src={userInfo ? userInfo?.profilePic : "https://bit.ly/dan-abramov"}
          />
        )}
        <Box marginLeft={"10px"}>
          <Text
            display={"flex"}
            alignItems={"center"}
            fontSize={"30px"}
            fontWeight={800}
            fontFamily={"heading"}
          >
            <Box marginRight={"5px"}>
              {loading ? (
                <Skeleton height="40px" margin={"4px 0"} width="300px" />
              ) : (
                userInfo?.username
              )}
            </Box>
            <GoVerified fontSize={"26"} color={"#3191eb"} />
          </Text>
          <Text marginTop={1} fontSize={"15px"} fontWeight={"normal"}>
            This page is too sensetive and enter your password to unlock
          </Text>
          <Stack marginTop={2}></Stack>

          <CustomListItem
            loading={loading}
            childrenHeader={
              <>
                <Flex alignItems={"center"} gap={"6px"} flexDirection={"row"}>
                  <EmailIcon /> Email
                </Flex>
              </>
            }
            children={userInfo?.email}
          />

          <CustomListItem
            loading={loading}
            childrenHeader={
              <Flex alignItems={"center"} gap={"6px"} flexDirection={"row"}>
                <SiStatuspage /> Status
              </Flex>
            }
            children={
              userInfo ? (userInfo?.Status ? "Active" : "Deactive") : "Error"
            }
          />

          <CustomListItem
            loading={loading}
            childrenHeader={
              <>
                <Flex alignItems={"center"} gap={"6px"} flexDirection={"row"}>
                  <MdDateRange /> Joined At
                </Flex>
              </>
            }
            children={joinedOn}
          />

          <CustomListItem
            loading={loading}
            childrenHeader={
              <>
                <Flex alignItems={"center"} gap={"6px"} flexDirection={"row"}>
                  <MdDateRange /> Updated At
                </Flex>
              </>
            }
            children={UpdatedAt}
          />
        </Box>
      </Stack>
    </Box>
  );
}

export default OverView2;

type CustomListItem = {
  childrenHeader: React.ReactNode;
  children: React.ReactNode;
  loading: any;
};

function CustomListItem({ children, childrenHeader, loading }: CustomListItem) {
  return (
    <Box
      background={useColorModeValue("gray.50", "gray.700")}
      borderRadius={"10px"}
      padding={"4px 12px"}
      marginTop={"10px"}
    >
      <Text fontWeight={"semibold"} marginTop={"9px !important"}>
        {childrenHeader}
      </Text>
      <Text marginTop={"0!important"}>
        {loading ? (
          <Skeleton height="20px" margin={"4px 0"} width="300px" />
        ) : (
          children
        )}
      </Text>
    </Box>
  );
}
