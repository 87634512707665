import {
  Box,
  Button,
  Flex,
  Icon,
  IconProps,
  Image,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import logo from "./../assets/makigifts-logo-header.png";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Footer from "./Footer";

interface SignInSignUpLayoutProps {
  children: ReactNode;
}

const SignInSignUpLayout: React.FC<SignInSignUpLayoutProps> = ({
  children,
}) => {
  const location = useLocation();
  return (
    <Flex direction="column" minHeight="100vh">
      {/* NavBar  */}
      <Flex
        py={2}
        borderRadius="10px"
        boxShadow="md"
        position="sticky"
        top={3}
        m={"15px"}
        backdropFilter={"blur(10px)"}
        bg={useColorModeValue("whiteAlpha.200", "whiteAlpha.200")}
        color={useColorModeValue("gray.600", "white")}
        minH={"50px"}
        zIndex={"111"}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
        justifyContent={"space-between"}
      >
        <RouterLink to={"/"}>
          <Image src={logo} alt="maki gifts Logo" height="50px" />
        </RouterLink>
        <Box display={"flex"} alignItems={"center"} gap={"10px"}>
          <ColorModeSwitcher justifySelf="flex-end" />
          {location.pathname === "/sign-in" ? (
            <RouterLink to="/sign-up">
              <Button colorScheme="teal" variant="solid">
                Sign Up
              </Button>
            </RouterLink>
          ) : (
            <RouterLink to="/sign-in">
              <Button colorScheme="teal" variant="solid">
                Sign In
              </Button>
            </RouterLink>
          )}
        </Box>
      </Flex>

      {/*child  */}

      <Box as="main" flex="1" p={4}>
        {children}
      </Box>

      {/* footer */}
      <Footer />
    </Flex>
  );
};

export default SignInSignUpLayout;

export const Blur = (props: IconProps) => {
  let width = useBreakpointValue({ base: "100%", md: "80%", lg: "70%" });
  let zIndex = useBreakpointValue({ base: 0, md: 0, lg: 0 });
  return (
    <Icon
      width={width}
      zIndex={zIndex}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="71" cy="61" r="111" fill="#F56565" />
      <circle cx="244" cy="106" r="139" fill="#ED64A6" />
      <circle cy="291" r="139" fill="#ED64A6" />
      <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
      <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
      <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
      <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
    </Icon>
  );
};
