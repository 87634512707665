import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Heading,
  Text,
  Button,
  useToast,
  Grid,
  Badge,
  useColorModeValue,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { getAllRoles } from "../../../../Redux/action/role.action";
import { truncateWords } from "../../../../util/uitil";

interface RoleModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectRole: (role: any) => void;
}

const RoleModal: React.FC<RoleModalProps> = ({
  isOpen,
  onClose,
  onSelectRole,
}) => {
  const [data, setData] = useState<any[]>([]); // set initial data
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();

  const _getAllRoles = useSelector(
    (state: { getAllRoles: any }) => state.getAllRoles
  );
  const { roles } = _getAllRoles;

  const toastPositions = useSelector((state: any) => state.toastPosition);

  useEffect(() => {
    dispatch<any>(getAllRoles(toastPositions, toast));
    setLoading(true);
  }, [dispatch, toast]);

  useEffect(() => {
    setData(roles);
    setFilteredData(
      data.filter((d) =>
        d.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      )
    );
    setLoading(false);
    // console.log(roles);

    if (searchTerm) {
      setData(filteredData);
    }
  }, [searchTerm, roles, data, page]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backdropFilter={"blur(20px)"}
        background={useColorModeValue("white", "blackAlpha.600")}
        borderWidth={"1px"}
        borderRadius={"15px"}
      >
        <ModalHeader>Select Role</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid
            templateColumns="repeat(auto-fill, minmax(150px, 1fr))"
            gap={3}
            mt={4}
          >
            {data.map((role: any) => (
              <Box
                key={role._id}
                p={4}
                borderWidth="1px"
                borderRadius="md"
                onClick={() => onSelectRole(role)}
                cursor="pointer"
                _hover={{ bg: "gray.400" }}
                position={"relative"}
              >
                <Badge
                  position={"absolute"}
                  top={"5px"}
                  right={"5px"}
                  bg={role.status === true ? "green.400" : "red.400"}
                  color={role.status === true ? "white" : "white"}
                  fontSize="13px"
                  p="3px 10px"
                  borderRadius="5px"
                >
                  {role.status ? "Active" : "InActive"}
                </Badge>

                {/* <Badge colorScheme="green">
                  {role.status ? "Active" : "inActive"}
                </Badge> */}
                <Heading size="md">{truncateWords(role.name, 15)}</Heading>
                <Text mt={2} color="gray.500">
                  {truncateWords(role.details, 20)}
                </Text>
              </Box>
            ))}
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RoleModal;
