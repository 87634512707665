import {
  Box,
  Button,
  Container,
  Divider,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  StackDivider,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import CustomFormControl from "./CustomFormControl";
import { useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { changePersonalAccountPassword } from "../../../../Redux/action/user.action";

function ChangePWD() {
  const [showNewPWD, setNewPWD] = useState(false);
  const [showOldPWD, setOldPWD] = useState(false);
  const [showConfirm, setComnfirm] = useState(false);

  const [CurrentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const toastPositions = useSelector((state: any) => state.toastPosition);

  const dispatch = useDispatch();
  const toast = useToast();

  const { loading } = useSelector(
    (state: { personalAccountPassword: any }) => state.personalAccountPassword
  );

  const handleClick = (type: string) => {
    if (type === "New") {
      setNewPWD(!showNewPWD);
    } else if (type === "Old") {
      setOldPWD(!showOldPWD);
    } else if (type === "Confirm") setComnfirm(!showConfirm);
  };

  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();

    if (confirmNewPassword !== newPassword) {
      return toast({
        title: "Password doesn't match",
        status: "error",
        duration: 3000,
        position: "top",
        isClosable: true,
      });
    }

    dispatch<any>(
      changePersonalAccountPassword(
        { CurrentPassword, NewPassword: newPassword },
        toastPositions,
        toast
      )
    );
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  };

  return (
    <Container
      maxWidth={"7xl"}
      borderRadius={"10px"}
      padding={"1rem!important"}
      boxShadow={"0 0 12px -10px"}
      // background={useColorModeValue("white", "gray.800")}
    >
      <Stack>
        <Stack flexDirection={"row"} justifyContent={"space-between"}>
          <Stack padding={"12px 0"}>
            <Text fontSize={"lg"} fontWeight={"medium"}>
              Change your password
            </Text>
            <Text color={"#4a5568"} marginTop={"0!important"} fontSize={"sm"}>
              This is the most secure part of your account
            </Text>
          </Stack>
        </Stack>
        <Divider marginBottom={"15px !important"} margin={"10px 0px"} />
        <Stack>
          <CustomFormControl
            children={
              <>
                <FormLabel
                  marginBottom={0}
                  //   alignSelf={"center"}
                  minWidth={"2xs"}
                >
                  Old password
                </FormLabel>

                <InputGroup>
                  <Input
                    pr="4.5rem"
                    type={showOldPWD ? "text" : "password"}
                    background={useColorModeValue("white", "gray.800")}
                    placeholder="Enter old password"
                    value={CurrentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      height="1.75rem"
                      size="sm"
                      onClick={() => handleClick("Old")}
                    >
                      {showOldPWD ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </>
            }
          />
          <StackDivider margin={"10px 0 !important"} borderBottomWidth={"0"} />

          <CustomFormControl
            children={
              <>
                <FormLabel
                  marginBottom={0}
                  //   alignSelf={"center"}
                  minWidth={"2xs"}
                >
                  New password
                </FormLabel>

                <InputGroup>
                  <Input
                    pr="4.5rem"
                    type={showNewPWD ? "text" : "password"}
                    background={useColorModeValue("white", "gray.800")}
                    placeholder="Enter new password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      height="1.75rem"
                      size="sm"
                      onClick={() => handleClick("New")}
                    >
                      {showNewPWD ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </>
            }
          />

          <StackDivider margin={"10px 0 !important"} borderBottomWidth={"0"} />

          <CustomFormControl
            children={
              <>
                <FormLabel
                  marginBottom={0}
                  //   alignSelf={"center"}
                  minWidth={"2xs"}
                >
                  Confirm New password
                </FormLabel>

                <InputGroup>
                  <Input
                    pr="4.5rem"
                    type={showConfirm ? "text" : "password"}
                    background={useColorModeValue("white", "gray.800")}
                    placeholder="Confirm New password"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      height="1.75rem"
                      size="sm"
                      onClick={() => handleClick("Confirm")}
                    >
                      {showConfirm ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </>
            }
          />

          <StackDivider margin={"10px 0 !important"} borderBottomWidth={"0"} />

          <Box display={"flex"} flexDirection={"row-reverse"}>
            <Button
              isLoading={loading}
              onClick={submitHandler}
              colorScheme="teal"
            >
              Change password
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
}

export default ChangePWD;
