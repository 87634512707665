import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useColorModeValue,
  Link as ChakraLink,
  Center,
  useToast,
  Code,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Link,
  VStack,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Blur } from "./SignInSignUpLayout";
import { useSelector } from "react-redux";

interface AuthFormProps {
  title: string;
  description: string;
  fields: { id: string; label: string; type: string; isRequired?: boolean }[];
  submitHandler: (e: React.FormEvent, formValues: any) => void;
  handleVerifyOTP: (e: React.FormEvent, formValues: any) => void;
  onOtpOpen: () => void;
  onOtpClose: () => void;
  otpModal: boolean;
  alternateText: string;
  alternateLink: string;
  alternateLinkText: string;
  children?: React.ReactNode;
}

const AuthForm: React.FC<AuthFormProps> = ({
  title,
  description,
  fields,
  submitHandler,
  handleVerifyOTP,
  onOtpClose,
  onOtpOpen,
  otpModal,
  alternateText,
  alternateLink,
  alternateLinkText,
  children,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [formValues, setFormValues] = useState<{ [key: string]: string }>({});
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [countdown, setCountdown] = useState(300); // 5 minutes
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const toastPositions = useSelector((state: any) => state.toastPosition);

  const navigate = useNavigate();
  const toast = useToast();

  const signIn = useSelector((state: { signIn: any }) => state.signIn);
  const { loading, error, userInfo } = signIn;

  const signInVerify = useSelector(
    (state: { signInVerify: any }) => state.signInVerify
  );
  const { loading: verifyLoading, userInfo: verifUserInfo } = signInVerify;

  const signUp = useSelector((state: { signUp: any }) => state.signUp);
  const { loading: signUpLoading, userInfo: signUpInfo } = signUp;

  const signUpVerify = useSelector(
    (state: { signUpVerify: any }) => state.signUpVerify
  );
  const { loading: signUpVerifyLoading, userInfo: signUpVerifyInfo } =
    signUpVerify;

  const { message: forgetPasswordInfo, loading: forgetPasswordLoading } =
    useSelector((state: { forgetPassword: any }) => state.forgetPassword);

  const { loading: resetPasswordLoading, error: resetError } = useSelector(
    (state: { resetPassword: any }) => state.resetPassword
  );

  useEffect(() => {
    if (errorMessage) {
      toast({
        title: errorMessage,
        status: "error",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
    }
    if (userInfo?.userInfo || signUpInfo?.userInfo) {
      navigate("/v1");
    }
    if (
      verifUserInfo === "OTP sent to your email." ||
      signUpVerifyInfo === "OTP sent to your email for verification." ||
      forgetPasswordInfo === "OTP sent to your email for password reset."
    ) {
      startCountdown();
      console.log("rerun", { signIn, signInVerify });
    }
  }, [
    navigate,
    userInfo,
    errorMessage,
    // loading,
    signUpInfo,
    // signUpLoading,
    verifUserInfo,
    signUpVerifyInfo,
    forgetPasswordInfo,
    toast,
  ]);

  // reset modal form if closed
  useEffect(() => {
    if (
      (resetError === "OTP expired try again" ||
        resetError === "No user found with this email address") &&
      otpModal === false
    ) {
      resetModalForm();
    }
  }, [resetError, otpModal]);

  const forBg = useColorModeValue("whiteAlpha.600", "blackAlpha.100");

  const handleChange = (id: string, value: string) => {
    setFormValues({ ...formValues, [id]: value });
  };

  const validateForm = () => {
    for (let field of fields) {
      if (field.isRequired && !formValues[field.id]) {
        setErrorMessage(`${field.label} is required`);
        return false;
      }
    }
    return true;
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      submitHandler(e, formValues);
    }
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const onOTPSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (
      title === "Reset Password" &&
      formValues?.newPassword !== formValues?.confirmNewPassword
    ) {
      return toast({
        title: `Password doesn't match!`,
        status: "warning",
        duration: 3000,
        position: "top",
        isClosable: true,
      });
    }

    handleVerifyOTP(e, formValues);
  };

  const startCountdown = () => {
    setCountdown(300); // Reset countdown time
    if (intervalRef.current) clearInterval(intervalRef.current); // Clear existing interval
    intervalRef.current = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 0) {
          clearInterval(intervalRef.current!);
          onOtpClose();
          resetModalForm();
          toast({
            title: "Retry Again",
            status: "error",
            duration: 5000,
            position: toastPositions ? toastPositions : "top-right",
            isClosable: true,
          });
          return prev;
        }
        return prev - 1;
      });
    }, 1000);
  };

  // ///////////////////////////////

  const handleOtpChange = (value: string) => {
    setFormValues({ ...formValues, ["otpCode"]: value });
  };

  const handleNewPasswordChange = (value: string) => {
    setFormValues({ ...formValues, ["newPassword"]: value });
  };

  const handleConfirmNewPasswordChange = (value: string) => {
    setFormValues({ ...formValues, ["confirmNewPassword"]: value });
  };

  const handleCancelModal = () => {
    resetModalForm();
    if (intervalRef.current) clearInterval(intervalRef.current);
    onOtpClose();
    setCountdown(0);
  };

  const resetModalForm = () => {
    setFormValues({ email: formValues.email });
  };

  return (
    <Box position="relative">
      <Container as={Center} maxW="6xl" py={10}>
        <Stack
          bg={useColorModeValue("whiteAlpha.600", "blackAlpha.600")}
          rounded="xl"
          borderWidth="1px"
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: "lg" }}
          zIndex={11}
          textAlign="center"
        >
          <Stack spacing={4}>
            <Heading
              lineHeight={1.1}
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
            >
              {title}{" "}
              <Text as="span" bg="teal.400" bgClip="text">
                !
              </Text>
            </Heading>
            <Text
              maxW={"72%"}
              alignSelf={"center"}
              color="gray.500"
              fontSize={{ base: "sm", sm: "md" }}
            >
              {description}{" "}
              <Text as="span" bg="teal" bgClip="text">
                !
              </Text>
            </Text>
          </Stack>

          <Box pt="0 !important" p={8}>
            <Stack spacing={4} as="form" onSubmit={onSubmit}>
              {fields.map((field) => (
                <FormControl
                  key={field.id}
                  id={field.id}
                  isRequired={field.isRequired}
                >
                  <FormLabel>{field.label}</FormLabel>
                  <InputGroup>
                    <Input
                      type={
                        field.type === "password" && showPassword
                          ? "text"
                          : field.type
                      }
                      value={formValues[field.id] || ""}
                      bg={forBg}
                      onChange={(e) => handleChange(field.id, e.target.value)}
                      isInvalid={errorMessage === `${field.label} is required`}
                    />
                    {field.type === "password" && (
                      <InputRightElement h="full">
                        <Button
                          variant="ghost"
                          onClick={() => setShowPassword((prev) => !prev)}
                        >
                          {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                        </Button>
                      </InputRightElement>
                    )}
                  </InputGroup>
                </FormControl>
              ))}
              {title === "Sign In" && (
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  align={"start"}
                  justify={"space-between"}
                >
                  <div></div>
                  {/* <Checkbox colorScheme={`${AlternativeColor}`}>Remember me</Checkbox> */}
                  <Link as={RouterLink} to={"/reset"} color={"teal.400"}>
                    Forgot password?
                  </Link>
                </Stack>
              )}

              <Stack spacing={10}>
                <Button
                  colorScheme="teal"
                  isLoading={
                    verifyLoading ||
                    signUpVerifyLoading ||
                    forgetPasswordLoading
                  }
                  type="submit"
                >
                  {title}
                </Button>
              </Stack>
              <Text color="gray.500" fontSize={{ base: "sm", sm: "md" }}>
                {alternateText}{" "}
                <ChakraLink as={RouterLink} to={alternateLink} color="teal">
                  {alternateLinkText}
                </ChakraLink>{" "}
                ✌️
              </Text>
            </Stack>
          </Box>
        </Stack>
      </Container>

      {children}

      <Modal
        onClose={() => {}}
        isOpen={otpModal}
        // onClose={() => setModalOpen(false)}
        size="sm"
        isCentered
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent
          backdropFilter={"blur(20px)"}
          background={useColorModeValue("whiteAlpha.500", "blackAlpha.600")}
          borderWidth={"1px"}
          borderRadius={"15px"}
          padding={"10px"}
        >
          <ModalHeader textAlign={"center"}> OTP Verification</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Text textAlign={"center"}>
                An OTP has been sent to <Code>{formValues["email"] || ""}</Code>
                . Please enter the code below to verify your account and {title}
              </Text>
              <Text fontWeight={"500"} mt={"10px"} textAlign={"center"}>
                {`Time remaining: ${Math.floor(countdown / 60)} minutes ${
                  countdown % 60
                } seconds`}
              </Text>
              <Center mt={6} mx={"18px"} flexDir={"column"}>
                {title === "Reset Password" && (
                  <FormLabel w={"100%"} mr={0}>
                    OTP Code
                  </FormLabel>
                )}
                <HStack>
                  <PinInput
                    onChange={handleOtpChange}
                    value={formValues["otpCode"] || ""}
                    otp
                    type="alphanumeric"
                    autoFocus
                  >
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
              </Center>

              {title === "Reset Password" && (
                <VStack mx={"18px"} mt={4} align="flex-start">
                  <FormLabel>New Password</FormLabel>
                  <InputGroup>
                    <Input
                      id="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter new password"
                      value={formValues["newPassword"] || ""}
                      onChange={(e) => handleNewPasswordChange(e.target.value)}
                      isInvalid={errorMessage === `password is required`}
                    />
                    <InputRightElement h="full">
                      <Button
                        variant="ghost"
                        onClick={() => setShowPassword((prev) => !prev)}
                      >
                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </VStack>
              )}

              {title === "Reset Password" && (
                <VStack mx={"18px"} mt={4} align="flex-start">
                  <FormLabel>Confirm New Password</FormLabel>

                  <InputGroup>
                    <Input
                      id="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="confirm new password"
                      value={formValues["confirmNewPassword"] || ""}
                      onChange={(e) =>
                        handleConfirmNewPasswordChange(e.target.value)
                      }
                      isInvalid={errorMessage === `password is required`}
                    />
                    <InputRightElement h="full">
                      <Button
                        variant="ghost"
                        onClick={() => setShowPassword((prev) => !prev)}
                      >
                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </VStack>
              )}
            </Box>
          </ModalBody>
          <ModalFooter gap={"10px"}>
            <Button
              colorScheme="green"
              onClick={onOTPSubmit}
              isLoading={signUpLoading || loading || resetPasswordLoading}
            >
              {title === "Reset Password"
                ? "Verify and Reset Password"
                : title === "Sign Up"
                ? "Verify and Sign Up"
                : "Verify and Sign In"}
            </Button>
            <Button onClick={handleCancelModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* bg */}
      <Blur
        position={"fixed"}
        top={"10%"}
        left={"10%"}
        style={{ filter: "blur(70px)" }}
        transform={"scale(1)"}
      />
    </Box>
  );
};

export default AuthForm;
