import {
  Box,
  Heading,
  Text,
  Button,
  Link,
  Image,
 } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import notFoundImage from "./../assets/page_not_found_re_e9o6.svg";
 import useDocumentTitle from "../interceptor/useDocumentTitle";
import NonLoginLayout from "../components/NonLoginLayout";

export default function PageNotFound() {
  useDocumentTitle("404");
  const navigate = useNavigate();
  return (
    <NonLoginLayout>
      <Box
        mt={"0"}
        textAlign="center"
        py={10}
        pt={0}
        px={6}
        height="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          src={notFoundImage}
          alt="Page not found"
          mx="auto"
          mb={6}
          maxW={"30rem"}
          w="100%"
        />

        <Heading
          display="inline-block"
          as="h2"
          size="2xl"
          bgGradient="linear(to-r, teal.400, teal.800)"
          backgroundClip="text"
        >
          404
        </Heading>
        <Text fontSize="18px" mt={3} mb={2}>
          Page Not Found
        </Text>
        <Text color={"gray.500"} mb={6}>
          The page you're looking for does not seem to exist
        </Text>

        <Link>
          <Button
            colorScheme="teal"
            bgGradient="linear(to-r, teal.400, teal.500, teal.800)"
            // color="white"
            variant="solid"
            onClick={() => navigate("/")}
          >
            Go to Home
          </Button>
        </Link>
      </Box>
    </NonLoginLayout>
  );
}
