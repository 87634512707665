import {
  Box,
  Flex,
  IconButton,
  Link,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllActiveSocialLinks } from "../Redux/action/socialLinks.action";
import { getIconComponent } from "../Pages/Admin/SocialLinks/SocialAdminPage";

const Footer = () => {
  const dispatch = useDispatch();
  const toast = useToast();

  const _allActiveSocialLinks = useSelector(
    (state: { allActiveSocialLinks: any }) => state.allActiveSocialLinks
  );
  const { loading: aboutUsLoading, socialLinks, error } = _allActiveSocialLinks;

  const toastPositions = useSelector((state: any) => state.toastPosition);

  useEffect(() => {
    dispatch<any>(getAllActiveSocialLinks(toastPositions, toast));
  }, [dispatch, toast]);

  return (
    <Box
      width={"full"}
      bg={useColorModeValue("gray.100", "gray.700")}
      py={4}
      px={6}
    >
      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box mb={{ base: 4, md: 0 }}>
          <Text fontSize="sm">© 2024 Maki Gifts</Text>
        </Box>
        <Box mb={{ base: 4, md: 0 }}>
          <Link as={RouterLink} mx={2} fontSize="sm" to={"/terms"}>
            Terms of Service
          </Link>
          <Link as={RouterLink} mx={2} fontSize="sm" to={"/privacy"}>
            Privacy Policy
          </Link>
          <Link as={RouterLink} mx={2} fontSize="sm" to={"/contact"}>
            Contact Us
          </Link>
        </Box>
        {!error && !aboutUsLoading && (
          <Flex my={{ base: "20px" }} justifyContent={"center"}>
            {socialLinks?.map((socialLink: any, index: any) => (
              <IconButton
                key={index}
                as={"a"}
                target="_blank"
                href={socialLink?.link}
                w={"20px"}
                h={"20px"}
                aria-label="Facebook"
                icon={getIconComponent(socialLink?.icon, socialLink?.color)}
                variant="solid"
                isRound
                marginX="0.5rem"
                colorScheme={socialLink?.color}
              />
            ))}
          </Flex>
        )}
      </Flex>
      <Box
        mt={{ base: "10px", md: "auto" }}
        textAlign={{ base: "center", md: "start" }}
        fontSize="sm"
      >
        Designed & Developed by{" "}
        <Link
          href="https://yeabsira3d.netlify.app"
          target="_blank"
          fontWeight="bold"
          _hover={{ textDecoration: "none" }} // Remove underline on hover
          style={{
            backgroundImage: "linear-gradient(96deg,#7500ff 28%,#f8018d)",
            backgroundClip: "text",
            color: "transparent",
          }}
        >
          Yeab Ad (U)
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;
