import {
  Box,
  Button,
  Flex,
  Grid,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BiCameraOff } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Loading, NoData } from "../../../components/Common/Components";
import PageHeader from "../../../components/PageHeader";
import {
  deleteSharePicture,
  getAllSharePictures,
} from "../../../Redux/action/picture.action";
import AddEditPictureForm from "./AddEditPictureForm";
import PictureCard from "./PictureCard";
import ErrorCard from "../../../components/Common/ErrorCard";
import useDocumentTitle from "../../../interceptor/useDocumentTitle";

const PictureAdminPage = () => {
  useDocumentTitle("Picture");
  const PAGE_SIZE = 6;
  const [data, setData] = useState<any[]>([]); // set initial data
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [sharePicturesToDelete, setsharePicturesToDelete] = useState<any>(null);
  const [editPictureId, setEditPictureId] = useState<string | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();
  const toast = useToast();

  const _allSharePictures = useSelector(
    (state: { allSharePictures: any }) => state.allSharePictures
  );
  const {
    loading: sharePicturesLoading,
    sharePictures,
    error,
  } = _allSharePictures;

  // create
  const _createSharePicture = useSelector(
    (state: { createSharePicture: any }) => state.createSharePicture
  );
  const {
    loading: createSharePicturesLoading,
    sharePicture: createSharePicture,
  } = _createSharePicture;
  // update
  const _updateSharePicture = useSelector(
    (state: { updateSharePicture: any }) => state.updateSharePicture
  );
  const {
    loading: updateSharePicturesLoading,
    sharePicture: updateSharePicture,
  } = _updateSharePicture;

  const _deleteSharePicture = useSelector(
    (state: { deleteSharePicture: any }) => state.deleteSharePicture
  );
  const {
    loading: deleteSharePictureLoading,
    sharePicture: deleteSharePicturePictures,
  } = _deleteSharePicture;

  const toastPositions = useSelector((state: any) => state.toastPosition);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleEdit = (id: string) => {
    setEditPictureId(id);
    onOpen();
  };

  const handleAdd = () => {
    setEditPictureId(null);
    onOpen();
  };

  const handleDelete = (project: any) => {
    setsharePicturesToDelete(project);
    setDeleteConfirmationOpen(true);
  };

  const handleConfirmDelete = () => {
    // Perform the delete action here
    if (sharePicturesToDelete) {
      dispatch<any>(
        deleteSharePicture(sharePicturesToDelete._id, toastPositions, toast)
      );
    }
    // Close the delete confirmation modal
    setDeleteConfirmationOpen(false);
  };

  useEffect(() => {
    dispatch<any>(getAllSharePictures(toastPositions, toast));
  }, [
    dispatch,
    createSharePicture,
    deleteSharePicturePictures,
    updateSharePicture,
    toast,
  ]);

  useEffect(() => {
    const startIndex = (page - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;

    setData(sharePictures);
    setFilteredData(
      data
        ?.filter((d) =>
          d.title?.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .slice(startIndex, endIndex)
    );
  }, [sharePictures, data, sharePicturesLoading, searchTerm, page]);

  return (
    <>
      <PageHeader
        onSearchChange={handleSearchChange}
        onButtonClick={handleAdd}
        searchTerm={searchTerm}
        listLength={data?.length}
        name="Picture"
        button={true}
      />
      {error ? (
        <Flex
          height={"50vh"}
          width={"full"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box width={"200px"}>
            <ErrorCard message={error} />
          </Box>
        </Flex>
      ) : (
        (sharePicturesLoading ||
          createSharePicturesLoading ||
          updateSharePicturesLoading ||
          deleteSharePictureLoading) && <Loading />
      )}

      {!(
        error ||
        sharePicturesLoading ||
        createSharePicturesLoading ||
        updateSharePicturesLoading ||
        deleteSharePictureLoading
      ) && filteredData?.length > 0 ? (
        <>
          <Grid templateColumns="repeat(auto-fill, minmax(230px, 1fr))" gap={6}>
            {filteredData?.map((sharePicture, index) => (
              <PictureCard
                onDelete={handleDelete}
                onEdit={handleEdit}
                sharePicture={sharePicture}
                key={index}
              />
            ))}
          </Grid>

          {data?.length > PAGE_SIZE && (
            <HStack mt={6}>
              {Array.from(
                Array(Math.ceil(data?.length / PAGE_SIZE)),
                (_, i) => i + 1
              ).map((p) => (
                <Button
                  key={p}
                  variant={page === p ? "solid" : "outline"}
                  size="sm"
                  onClick={() => handlePageChange(p)}
                >
                  {p}
                </Button>
              ))}
            </HStack>
          )}
        </>
      ) : (
        !(
          error ||
          sharePicturesLoading ||
          createSharePicturesLoading ||
          updateSharePicturesLoading ||
          deleteSharePictureLoading
        ) && <NoData icon={BiCameraOff} />
      )}

      <Modal
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent
          background={"transparent"}
          // backdropFilter={"blur(20px)"}
          // background={useColorModeValue("whiteAlpha.700", "blackAlpha.600")}
          borderWidth={"1px"}
          borderRadius={"15px"}
          overflow={"hidden"}
        >
          <Box
            backdropFilter={"blur(20px)"}
            background={useColorModeValue("whiteAlpha.700", "blackAlpha.600")}
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            zIndex={-111}
          ></Box>

          <ModalHeader>
            {editPictureId ? "Edit Picture" : "Add Picture"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddEditPictureForm
              isEdit={!!editPictureId}
              pictureId={editPictureId}
              onClose={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* delete */}
      <Modal
        isOpen={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        size="sm"
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          backdropFilter={"blur(20px)"}
          background={useColorModeValue("whiteAlpha.500", "blackAlpha.600")}
          borderWidth={"1px"}
          borderRadius={"15px"}
        >
          <ModalHeader>Delete Pictures</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete this Pictures?</Text>
          </ModalBody>
          <ModalFooter gap={"10px"}>
            <Button colorScheme="red" onClick={handleConfirmDelete}>
              Delete
            </Button>
            <Button onClick={() => setDeleteConfirmationOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PictureAdminPage;
