import { useDispatch, useSelector } from "react-redux";
import AuthForm from "../components/AuthForm";
import { useToast } from "@chakra-ui/react";
import { SignInAction, signInVerify } from "../Redux/action/user.action";
import { useState } from "react";
import useDocumentTitle from "../interceptor/useDocumentTitle";

const SignInPage = () => {
  useDocumentTitle("Sign In");
  const toastPositions = useSelector((state: any) => state.toastPosition);
  const [otpModal, setOtpModal] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();

  const onOtpOpen = () => {
    setOtpModal(true);
  };

  const onOtpClose = () => {
    setOtpModal(false);
  };

  const submitHandler = async (e: any, formValues: any) => {
    e.preventDefault();
    dispatch<any>(SignInAction(formValues, toastPositions, toast, onOtpOpen));
  };

  const handleVerifyOTP = async (e: any, formValues: any) => {
    e.preventDefault();
    dispatch<any>(signInVerify(formValues, toastPositions, toast));
  };

  return (
    <>
      <AuthForm
        onOtpClose={onOtpClose}
        onOtpOpen={onOtpOpen}
        otpModal={otpModal}
        handleVerifyOTP={handleVerifyOTP}
        title="Sign In"
        description="Sign in now and explore your journey"
        fields={[
          {
            id: "email",
            label: "Email address",
            type: "email",
            isRequired: true,
          },
          {
            id: "password",
            label: "Password",
            type: "password",
            isRequired: true,
          },
        ]}
        submitHandler={submitHandler}
        alternateText="Don't have an account?"
        alternateLink="/sign-up"
        alternateLinkText="Create Now"
      />
    </>
  );
};

export default SignInPage;
