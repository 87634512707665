import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Progress,
  Stack,
  Switch,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import PageHeader from "../../../components/PageHeader";
import { BiCloudUpload } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {
  createShareVideo,
  getShareVideoById,
  updateShareVideo,
} from "../../../Redux/action/video.action";
import { Loading } from "../../../components/Common/Components";
import { qrCodeURL } from "../../../util/uitil";
import QRCodeGenerator from "../../../components/QRCode";
import useDocumentTitle from "../../../interceptor/useDocumentTitle";

interface AddEditVideoProps {
  isEdit?: boolean;
}

const AddEditVideoPage: React.FC<AddEditVideoProps> = ({ isEdit }) => {
  useDocumentTitle("Edit Video");
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const [videoPreview, setVideoPreview] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    title: "",
    text: "",
    captions: "",
    video: null as File | null,
    status: false,
  });
  const { loading, shareVideo, error } = useSelector(
    (state: any) => state.shareVideoById
  );
  const toastPositions = useSelector((state: any) => state.toastPosition);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  useEffect(() => {
    if (isEdit && id) {
      dispatch<any>(getShareVideoById(id, toastPositions, toast));
    }
  }, [dispatch, id, isEdit]);

  useEffect(() => {
    if (isEdit && shareVideo) {
      setFormData({
        title: shareVideo.title,
        text: shareVideo.text,
        captions: shareVideo.captions,
        video: shareVideo.video,
        status: shareVideo.status,
      });
      if (videoPreview) {
        URL.revokeObjectURL(videoPreview);
      }
      setVideoPreview(shareVideo.video);
    }
  }, [shareVideo, isEdit]);

  useEffect(() => {
    return () => {
      if (videoPreview) {
        URL.revokeObjectURL(videoPreview);
      }
    };
  }, [videoPreview]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: checked }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setFormData((prevData) => ({ ...prevData, video: file }));
    // console.log(e.target.files);
    setVideoPreview(null);
    if (file) {
      if (videoPreview) {
        URL.revokeObjectURL(videoPreview);
      }
      setVideoPreview(URL.createObjectURL(file).toString());
      // console.log(videoPreview, file, URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const form = new FormData();
    form.append("title", formData.title);
    form.append("text", formData.text);
    form.append("captions", formData.captions);
    if (formData.video) {
      form.append("video", formData.video);
    }
    form.append("status", JSON.stringify(formData.status));

    // if (isEdit) {
    //   dispatch<any>(
    //     updateShareVideo(id ? id : "", form, toastPositions, toast)
    //   );
    // } else {
    //   dispatch<any>(createShareVideo(formData, toastPositions, toast));
    // }

    const onUploadProgress = (progressEvent: any) => {
      const { loaded, total } = progressEvent;
      setUploadProgress(Math.round((loaded * 100) / total));
    };

    if (isEdit) {
      dispatch<any>(
        updateShareVideo(
          id ? id : "",
          navigate,
          form,
          toastPositions,
          toast,
          onUploadProgress
        )
      );
    } else {
      dispatch<any>(
        createShareVideo(
          navigate,
          form,
          toastPositions,
          toast,
          onUploadProgress
        )
      );
    }
  };

  // const onDrop = (acceptedFiles: File[]) => {
  //   if (acceptedFiles.length > 0) {
  //     const file = acceptedFiles[0];
  //     setFormData((prevData) => ({ ...prevData, video: file }));
  //     setVideoPreview(URL.createObjectURL(file));
  //     console.log(acceptedFiles);
  //   }
  // };

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      if (videoPreview) {
        URL.revokeObjectURL(videoPreview);
      }
      setFormData((prevData) => ({ ...prevData, video: file }));
      setVideoPreview(URL.createObjectURL(file));
    }
  };

  const handleDropClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <>
      <PageHeader
        listLength={0}
        name={`${isEdit ? "Edit" : "Add"} Video`}
        subName="Please fill out the form"
      />

      {loading && <Loading />}

      {!loading && (
        <Container
          borderWidth={"1px"}
          borderRadius={"15px"}
          p={"15px"}
          m={"15px"}
          width={"unset"}
          maxW={"unset"}
          position={"relative"}
          onSubmit={handleSubmit}
          as={"form"}
        >
          <Box
            position={"absolute"}
            right={"1rem"}
            top={"-5rem"}
            textAlign="right"
          >
            <Button
              isDisabled={uploadProgress > 0}
              px={10}
              type="submit"
              colorScheme="teal"
            >
              {isEdit ? "Update" : "Save"}
            </Button>
          </Box>
          <Stack spacing={5}>
            <FormControl>
              <FormLabel mb={0} fontSize={"lg"} htmlFor="bio">
                Title
              </FormLabel>
              <FormHelperText mb={2} mt={0} id="bio-helptext">
                Add title for this doc
              </FormHelperText>
              <Input
                id="title"
                name="title"
                placeholder="add title here"
                value={formData.title}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl>
              <FormLabel mb={0} fontSize={"lg"} htmlFor="bio">
                Text
              </FormLabel>
              <FormHelperText mb={2} mt={0} id="text-helptext">
                Add text like a memory or anything that describes the video
              </FormHelperText>
              <Textarea
                id="text"
                name="text"
                placeholder="add text here"
                rows={3}
                aria-describedby="text-helptext"
                value={formData.text}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl>
              <FormLabel mb={0} fontSize={"lg"} htmlFor="bio">
                Captions
              </FormLabel>
              <FormHelperText mb={2} mt={0} id="captions-helptext">
                Add captions here for the video
              </FormHelperText>
              <Input
                id="captions"
                name="captions"
                placeholder="add captions here"
                value={formData.captions}
                onChange={handleInputChange}
              />
            </FormControl>

            {id && (
              <FormControl>
                <FormLabel mb={0} fontSize={"lg"}>
                  Qr Code
                </FormLabel>
                <FormHelperText mb={2} mt={0} id="status-helptext">
                  Share this qr code and it will display the content only if the
                  status is on
                </FormHelperText>

                <QRCodeGenerator url={qrCodeURL(`v/${id}`)} />
              </FormControl>
            )}

            <FormControl position={"unset"}>
              <FormLabel mb={0} fontSize={"lg"} htmlFor="video">
                Video
              </FormLabel>
              <FormHelperText mb={2} mt={0} id="bio-helptext">
                Add Video to be shared
              </FormHelperText>

              <Flex
                alignItems={"center"}
                justifyContent={"center"}
                borderWidth={"1px"}
                width={"full"}
                p={"15px"}
                my={"15px"}
                borderRadius={"15px"}
                onClick={handleDropClick}
                onDrop={(event) => {
                  event.preventDefault();
                  const files = event.dataTransfer.files;
                  if (files.length > 0) {
                    onDrop(Array.from(files));
                  }
                }}
                onDragOver={(event) => event.preventDefault()}
              >
                <Input
                  type="file"
                  id="video"
                  name="video"
                  accept="video/*"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <Stack alignItems={"center"} spacing={1}>
                  <Stack direction="row" spacing={2} align="center">
                    <Button colorScheme="teal">
                      <BiCloudUpload fontSize={26} />
                    </Button>
                  </Stack>
                  <Stack alignItems="center">
                    <Text>or drag and drop</Text>
                    <Text fontSize="sm" color="gray.500">
                      MP4, AVI, or MOV up to 100MB
                    </Text>
                  </Stack>
                </Stack>
              </Flex>
              {videoPreview && (
                <Box textAlign={"center"} mt={4}>
                  <FormHelperText mb={2} mt={0} id="bio-helptext">
                    The selected Video is displayed here
                  </FormHelperText>
                  <Box borderRadius={"15px"} overflow={"hidden"}>
                    <video width="100%" controls>
                      <source src={videoPreview} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </Box>
                </Box>
              )}
              {uploadProgress > 0 && (
                <Box
                  className="video"
                  position={"absolute"}
                  background={"#00000069"}
                  backdropFilter={"blur(10px)"}
                  borderRadius={"10px"}
                  top={"0"}
                  height={"98.3%"}
                  width={"97.5%"}
                  mt={4}
                  padding={"10px"}
                  display={"flex"}
                  flexDir={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  zIndex={11}
                >
                  {/* <Center> */}
                  <Progress
                    hasStripe
                    w={"100%"}
                    colorScheme="green"
                    size={"lg"}
                    borderRadius={"10px"}
                    value={uploadProgress}
                  />
                  <Center mt={"10px"} fontSize={"20px"} fontWeight={"bold"}>
                    Progress {uploadProgress}%
                  </Center>
                  {/* </Center> */}
                </Box>
              )}
            </FormControl>

            <FormControl>
              <FormLabel mb={0} fontSize={"lg"}>
                Status
              </FormLabel>
              <FormHelperText mb={2} mt={0} id="status-helptext">
                On means this is shared and available for public.
              </FormHelperText>
              <Switch
                colorScheme="teal"
                name="status"
                isChecked={formData.status}
                onChange={handleSwitchChange}
              />
            </FormControl>
          </Stack>
          <Divider my={6} />
          <Box textAlign="right">
            <Button px={10} type="submit" colorScheme="teal">
              {isEdit ? "Update" : "Save"}
            </Button>
          </Box>
        </Container>
      )}
    </>
  );
};

export default AddEditVideoPage;
