import axios from "axios";
import {
  GET_ALL_PERMISSIONS_REQUEST,
  GET_ALL_PERMISSIONS_SUCCESS,
  GET_ALL_PERMISSIONS_FAIL,
  GET_ACTIVE_PERMISSIONS_REQUEST,
  GET_ACTIVE_PERMISSIONS_SUCCESS,
  GET_ACTIVE_PERMISSIONS_FAIL,
  GET_PERMISSION_BY_ID_REQUEST,
  GET_PERMISSION_BY_ID_SUCCESS,
  GET_PERMISSION_BY_ID_FAIL,
  CREATE_PERMISSION_REQUEST,
  CREATE_PERMISSION_SUCCESS,
  CREATE_PERMISSION_FAIL,
  UPDATE_PERMISSION_REQUEST,
  UPDATE_PERMISSION_SUCCESS,
  UPDATE_PERMISSION_FAIL,
  DELETE_PERMISSION_REQUEST,
  DELETE_PERMISSION_SUCCESS,
  DELETE_PERMISSION_FAIL,
} from "../constant/permission.constant";
import { UseToastOptions } from "@chakra-ui/react";
import { handleErrorMessage } from "../../interceptor/ErrorHandler";
import API_BASE_URL from "../../util/config";
import { getConfig } from "./common/action";

export const getAllPermissions =
  (
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_ALL_PERMISSIONS_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.get(`${API_BASE_URL}permissions`, config);
      dispatch({ type: GET_ALL_PERMISSIONS_SUCCESS, payload: data });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_ALL_PERMISSIONS_FAIL
      );
    }
  };

export const getActivePermissions =
  (
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_ACTIVE_PERMISSIONS_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.get(
        `${API_BASE_URL}permissions/get/active`,
        config
      );
      dispatch({ type: GET_ACTIVE_PERMISSIONS_SUCCESS, payload: data });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_ACTIVE_PERMISSIONS_FAIL
      );
    }
  };

export const getPermissionById =
  (
    id: string,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_PERMISSION_BY_ID_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.get(
        `${API_BASE_URL}permissions/${id}`,
        config
      );
      dispatch({ type: GET_PERMISSION_BY_ID_SUCCESS, payload: data });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_PERMISSION_BY_ID_FAIL
      );
    }
  };
export const createPermission =
  (
    permission: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void,
    onClose: () => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: CREATE_PERMISSION_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.post(
        `${API_BASE_URL}permissions`,
        permission,
        config
      );
      dispatch({ type: CREATE_PERMISSION_SUCCESS, payload: data });

      // Add toast notification
      toasts({
        title: `Permission created successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });

      onClose();
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        CREATE_PERMISSION_FAIL
      );
    }
  };

export const updatePermission =
  (
    id: string,
    permission: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void,
    onClose: () => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: UPDATE_PERMISSION_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.put(
        `${API_BASE_URL}permissions/${id}`,
        permission,
        config
      );
      dispatch({ type: UPDATE_PERMISSION_SUCCESS, payload: data });

      // Add toast notification
      toasts({
        title: `Permission updated successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });

      onClose();
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        UPDATE_PERMISSION_FAIL
      );
    }
  };

export const deletePermission =
  (
    id: string,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: DELETE_PERMISSION_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.delete(
        `${API_BASE_URL}permissions/${id}`,
        config
      );
      dispatch({ type: DELETE_PERMISSION_SUCCESS, payload: data });

      // Add toast notification
      toasts({
        title: `Permission deleted successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        DELETE_PERMISSION_FAIL
      );
    }
  };
