import { Box, Heading, Text, Link } from "@chakra-ui/react"; 
import useDocumentTitle from "../interceptor/useDocumentTitle";
import NonLoginLayout from "../components/NonLoginLayout";

const PrivacyPolicy = () => {
  useDocumentTitle("Our Policy");

  return (
    <NonLoginLayout>
      <Box mt={"20px"} p={8}>
        <Heading as="h1" size="xl" mb={4}>
          Privacy Policy
        </Heading>

        <Text fontSize="lg" mb={6}>
          <strong>Introduction</strong>
          <br />
          Welcome to Maki Gifts! We are committed to protecting your privacy and
          ensuring the security of your personal information. This Privacy
          Policy explains how we collect, use, and protect the information you
          provide when using our website and services.
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          Information We Collect
        </Heading>

        <Text fontSize="lg" mb={6}>
          <strong>Personal Information</strong>
          <br />
          When you order a custom product or interact with our services, we may
          collect personal information such as your name, email address,
          shipping address, and payment details. This information is used to
          fulfill your orders and provide you with the best possible service.
        </Text>

        <Text fontSize="lg" mb={6}>
          <strong>Shared Data</strong>
          <br />
          When you request to share pictures, text, or videos using a QR code
          generated by Maki Gifts, the QR code redirects to a custom URL that
          displays the shared data. This shared data may include memories,
          messages, or other content that you choose to share.
        </Text>

        <Text fontSize="lg" mb={6}>
          <strong>Usage Data</strong>
          <br />
          We also collect non-personal information about how you interact with
          our website and services, such as your IP address, browser type, and
          device information. This information helps us improve our website's
          performance and user experience.
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          Use of Information
        </Heading>

        <Text fontSize="lg" mb={6}>
          <strong>Custom Product Orders</strong>
          <br />
          We use your personal information to process and fulfill your custom
          product orders. This includes shipping your orders to the specified
          address and contacting you regarding order updates or inquiries.
        </Text>

        <Text fontSize="lg" mb={6}>
          <strong>Shared Content</strong>
          <br />
          The shared data accessed through QR codes or custom URLs is intended
          for personal use and viewing by individuals who have access to the
          shared link or QR code. If you choose to share the link or QR code
          with others, they will be able to view the shared content.
        </Text>

        <Text fontSize="lg" mb={6}>
          <strong>Marketing and Communication</strong>
          <br />
          With your consent, we may use your email address to send promotional
          offers, newsletters, or updates about our products and services. You
          can opt-out of these communications at any time by following the
          unsubscribe instructions provided in the emails.
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          Data Security
        </Heading>

        <Text fontSize="lg" mb={6}>
          We take the security of your information seriously and employ
          industry-standard security measures to protect your personal data from
          unauthorized access, disclosure, or alteration. However, no method of
          transmission over the internet or electronic storage is completely
          secure, so we cannot guarantee absolute security.
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          Third-Party Services
        </Heading>

        <Text fontSize="lg" mb={6}>
          Maki Gifts may use third-party services or tools to enhance our
          website's functionality, analyze user behavior, or process payments.
          These third-party services have their own privacy policies and terms
          of use, and we encourage you to review them when accessing these
          services.
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          Your Choices
        </Heading>

        <Text fontSize="lg" mb={6}>
          You have the right to access, update, or delete your personal
          information held by Maki Gifts. If you have any questions, concerns,
          or requests regarding your data privacy, please contact us using the
          information provided below.
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          Changes to this Privacy Policy
        </Heading>

        <Text fontSize="lg" mb={6}>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or legal requirements. We will notify you of any
          significant changes by posting the updated policy on our website or
          through other communication channels.
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          Contact Us
        </Heading>

        <Text fontSize="lg" mb={6}>
          If you have any questions, comments, or concerns about this Privacy
          Policy or our data practices, please contact us at{" "}
          <Link color={"teal.400"} href="mailto:contact@makigifts.com">
            contact@makigifts.com
          </Link>
          .
        </Text>
      </Box>
    </NonLoginLayout>
  );
};

export default PrivacyPolicy;
