import { LockIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Center,
  Text,
  Stack,
  Button,
  useColorModeValue,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  InputRightElement,
  List,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLoggedInUser } from "../../../../Redux/action/user.action";

function LockCard() {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const toastPositions = useSelector((state: any) => state.toastPosition);

  const signUp = useSelector((state: { signUp: any }) => state.signUp);
  const { userInfo } = signUp;

  const _loggedInUser = useSelector(
    (state: { loggedInUser: any }) => state.loggedInUser
  );
  const { loading } = _loggedInUser;

  useEffect(() => {
    if (!userInfo?.userInfo) {
      navigate("/");
    }
    // if (loading) {
    //   toast({
    //     title: "Loading ...",
    //     status: "loading",
    //     duration: 3000,
    //     position: "top-right",
    //     isClosable: true,
    //   });
    // }
    // if (error) {
    //   toast({
    //     title: error,
    //     status: "error",
    //     duration: 3000,
    //     position: "top-right",
    //     isClosable: true,
    //   });
    // }
  }, [navigate, userInfo]);

  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();

    dispatch<any>(getLoggedInUser(password, toastPositions, toast));
  };

  return (
    <div>
      <Center py={6}>
        <Box
          maxW={"330px"}
          w={"full"}
          bg={useColorModeValue("white", "gray.800")}
          boxShadow={"0 0 10px #46464669"}
          rounded={"15px"}
          overflow={"hidden"}
        >
          <Stack
            background={`linear-gradient(rgb(0 0 0 / 50%), rgb(0 0 0 / 50%)) ,url(https://wallpaperaccess.com/full/4268828.png)`}
            bgSize={"cover"}
            bgRepeat={"no-repeat"}
            backgroundPosition={"center"}
            textAlign={"center"}
            p={6}
            color={useColorModeValue("gray.800", "white")}
            align={"center"}
            textColor={"white"}
            position={"relative"}
          >
            <Text
              fontSize={"sm"}
              fontWeight={500}
              bg={"linear-gradient(45deg, #d14f85, #bb4848)"}
              boxShadow={"0 0 18px 5px #bb486d"}
              p={"4px"}
              px={3}
              color={"white"}
              rounded={"full"}
              position={"absolute"}
              right={"8px"}
              bottom={"8px"}
              display={"flex"}
              alignItems={"center"}
            >
              <LockIcon marginRight={"4px"} /> <span>Locked</span>
            </Text>

            <Avatar
              marginTop={"0 !important"}
              minWidth={"130px"}
              minHeight={"130px"}
              name={
                userInfo?.userInfo
                  ? userInfo?.userInfo?.username
                  : "Dan Abrahmov"
              }
              src={
                userInfo?.userInfo
                  ? userInfo?.userInfo?.profilePic
                  : "https://bit.ly/sf-abramov"
              }
            />
          </Stack>

          <Box
            bg={useColorModeValue("gray.50", "gray.900")}
            px={6}
            paddingTop={2}
            paddingBottom={5}
          >
            <List spacing={3}>
              <Box textAlign={"center"}>
                <Text
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  fontSize={"3xl"}
                  fontWeight={800}
                >
                  <LockIcon marginRight={"4px"} /> <span>Protected</span>
                </Text>
                <Text fontSize={"sm"} fontWeight={200}>
                  This page is too sensetive and enter your password to unlock
                </Text>
              </Box>

              <FormControl id="password" isRequired>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputRightElement h={"full"}>
                    <Button
                      variant={"ghost"}
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }
                    >
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </List>

            <Button
              isLoading={loading}
              mt={"15px"}
              w={"full"}
              colorScheme="teal"
              rounded={"xl"}
              onClick={submitHandler}
            >
              Unlock
            </Button>
          </Box>
        </Box>
      </Center>
    </div>
  );
}

export default LockCard;
