import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Switch,
  VStack,
} from "@chakra-ui/react";

interface FormData {
  name: string;
  details: string;
  status: boolean;
}

interface AddPermissionFormProps {
  onSubmit: (data: FormData) => void;
  closeModal: () => void;
}

const AddPermissionForm: React.FC<AddPermissionFormProps> = ({
  onSubmit,
  closeModal,
}) => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    details: "",
    status: true,
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;

    if (type === "checkbox") {
      const isChecked = (e.target as HTMLInputElement).checked;
      setFormData((prevData) => ({
        ...prevData,
        [name]: isChecked,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
    setFormData({ name: "", details: "", status: true });
    closeModal();
  };

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing={4} align="stretch">
        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input
            type="text"
            name="name"
            isRequired={true}
            value={formData.name}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Description</FormLabel>
          <Input
            type="text"
            isRequired={true}
            name="details"
            value={formData.details}
            onChange={handleInputChange}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Status</FormLabel>
          <Switch
            colorScheme="green"
            name="status"
            isChecked={formData.status}
            onChange={handleInputChange}
          />
        </FormControl>

        <ModalFooter>
          <Button mr={4} type="submit" colorScheme="teal">
            Add Permission
          </Button>
          <Button onClick={closeModal}>Close</Button>
        </ModalFooter>
      </VStack>
    </form>
  );
};

export default AddPermissionForm;
