import axios from "axios";
import {
  GET_ALL_LANDING_REQUEST,
  GET_ALL_LANDING_SUCCESS,
  GET_ALL_LANDING_FAIL,
  GET_ALL_ACTIVE_LANDING_REQUEST,
  GET_ALL_ACTIVE_LANDING_SUCCESS,
  GET_ALL_ACTIVE_LANDING_FAIL,
  GET_LANDING_BY_ID_REQUEST,
  GET_LANDING_BY_ID_SUCCESS,
  GET_LANDING_BY_ID_FAIL,
  CREATE_LANDING_REQUEST,
  CREATE_LANDING_SUCCESS,
  CREATE_LANDING_FAIL,
  UPDATE_LANDING_REQUEST,
  UPDATE_LANDING_SUCCESS,
  UPDATE_LANDING_FAIL,
  DELETE_LANDING_REQUEST,
  DELETE_LANDING_SUCCESS,
  DELETE_LANDING_FAIL,
} from "../constant/landing.constant";
import { UseToastOptions } from "@chakra-ui/react";
import { handleErrorMessage } from "../../interceptor/ErrorHandler";
import {
  getConfig,
   getConfigForFileUploads,
} from "./common/action";
import API_BASE_URL from "../../util/config";

export const getAllLanding =
  (
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_ALL_LANDING_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.get(`${API_BASE_URL}landing`, config);
      dispatch({ type: GET_ALL_LANDING_SUCCESS, payload: data });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_ALL_LANDING_FAIL
      );
    }
  };

export const getAllActiveLanding =
  (
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_ALL_ACTIVE_LANDING_REQUEST });
      const { data } = await axios.get(`${API_BASE_URL}landing/get/active`);
 
      dispatch({ type: GET_ALL_ACTIVE_LANDING_SUCCESS, payload: data });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_ALL_ACTIVE_LANDING_FAIL
      );
    }
  };

export const getLandingById =
  (
    id: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_LANDING_BY_ID_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.get(`${API_BASE_URL}landing/${id}`, config);
      dispatch({ type: GET_LANDING_BY_ID_SUCCESS, payload: data });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_LANDING_BY_ID_FAIL
      );
    }
  };
export const createLanding =
  (
    landing: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void,
    onUploadProgress: (progressEvent: any) => void,
    onClose: () => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: CREATE_LANDING_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfigForFileUploads(userInfo, onUploadProgress);

      const { data } = await axios.post(
        `${API_BASE_URL}landing`,
        landing,
        config
      );
      dispatch({ type: CREATE_LANDING_SUCCESS, payload: data });

      // Add toast notification
      toasts({
        title: `Landing created successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });

      onClose();
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        CREATE_LANDING_FAIL
      );
    }
  };

export const updateLanding =
  (
    id: string,
    landing: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void,
    onUploadProgress: (progressEvent: any) => void,
    onClose: () => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: UPDATE_LANDING_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfigForFileUploads(userInfo, onUploadProgress);

      const { data } = await axios.put(
        `${API_BASE_URL}landing/${id}`,
        landing,
        config
      );
      dispatch({ type: UPDATE_LANDING_SUCCESS, payload: data });

      // Add toast notification
      toasts({
        title: `Landing updated successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });

      onClose();
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        UPDATE_LANDING_FAIL
      );
    }
  };

export const deleteLanding =
  (
    id: string,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: DELETE_LANDING_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.delete(
        `${API_BASE_URL}landing/${id}`,
        config
      );
      dispatch({ type: DELETE_LANDING_SUCCESS, payload: data });

      // Add toast notification
      toasts({
        title: `Landing deleted successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        DELETE_LANDING_FAIL
      );
    }
  };
