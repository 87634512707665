import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Stack,
  Switch,
  useToast,
} from "@chakra-ui/react";
import PageHeader from "../../../components/PageHeader";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Loading } from "../../../components/Common/Components";
import {
  createShareText,
  getShareTextById,
  updateShareText,
} from "../../../Redux/action/text.actions";
import "draft-js/dist/Draft.css";
import {
  Editor,
  EditorState,
  RichUtils,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import {
  FaBold,
  FaHeading,
  FaIndent,
  FaItalic,
  FaLink,
  FaListOl,
  FaListUl,
  FaOutdent,
  FaQuoteRight,
  FaRedo,
  FaTable,
  FaUnderline,
  FaUndo,
} from "react-icons/fa";
import "./FormStyle.css";
import { BiHighlight, BiStrikethrough } from "react-icons/bi";
import { qrCodeURL } from "../../../util/uitil";
import QRCodeGenerator from "../../../components/QRCode";
import ErrorCard from "../../../components/Common/ErrorCard";
import useDocumentTitle from "../../../interceptor/useDocumentTitle";

interface AddEditTextProps {
  isEdit?: boolean;
}

const AddEditTextPage: React.FC<AddEditTextProps> = ({ isEdit }) => {
  useDocumentTitle("Edit Text");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const styleMap = {
    STRIKETHROUGH: {
      textDecoration: "line-through",
    },
    HIGHLIGHT: {
      backgroundColor: "yellow",
    },
    // Add more custom styles as needed
  };

  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [formData, setFormData] = useState({
    title: "",
    text: "",
    captions: "",
    media: "",
    status: false,
  });

  const { loading, shareText, error } = useSelector(
    (state: any) => state.shareTextById
  );

  // create
  const { shareText: createText, loading: createLoading } = useSelector(
    (state: { createShareText: any }) => state.createShareText
  );
  // update
  const { shareText: updateText, loaded: updateLoading } = useSelector(
    (state: { updateShareText: any }) => state.updateShareText
  );

  const toastPositions = useSelector((state: any) => state.toastPosition);

  useEffect(() => {
    if (isEdit && id) {
      dispatch<any>(getShareTextById(id, toastPositions, toast));
    }
  }, [dispatch, id, isEdit]);

  useEffect(() => {
    if (isEdit && shareText) {
      setFormData({
        title: shareText.title,
        text: shareText.text,
        captions: shareText.captions.join(", "),
        media: shareText.media,
        status: shareText.status,
      });

      const rawContentState = markdownToDraft(shareText.text);
      const contentState = convertFromRaw(rawContentState);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [shareText, isEdit]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: checked }));
  };

  const handleEditorChange = (editorState: EditorState) => {
    setEditorState(editorState);
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const markdown = draftToMarkdown(rawContentState);
    setFormData((prevData) => ({ ...prevData, text: markdown }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const form = new FormData();
    form.append("title", formData.title);
    form.append("text", formData.text);
    form.append("captions", formData.captions);
    form.append("status", JSON.stringify(formData.status));

    if (isEdit) {
      dispatch<any>(
        updateShareText(id ? id : "", form, toastPositions, toast, navigate)
      );
    } else {
      dispatch<any>(createShareText(formData, toastPositions, toast, navigate));
    }
  };

  const undo = () => {
    setEditorState(EditorState.undo(editorState));
  };

  const redo = () => {
    setEditorState(EditorState.redo(editorState));
  };

  const handleKeyCommand = (command: string, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const toggleInlineStyle = (style: string) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };

  const toggleBlockType = (blockType: string) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const exportToMarkdown = () => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const markdown = draftToMarkdown(rawContentState);
    // console.log(markdown);
  };

  const toolbarButton = (
    onClick: () => void,
    icon: JSX.Element,
    label: string
  ) => (
    <IconButton
      onClick={onClick}
      icon={icon}
      aria-label={label}
      size="sm"
      variant="outline"
      borderWidth={0}
      colorScheme="teal"
    />
  );

  return (
    <>
      <PageHeader
        listLength={0}
        name={`${isEdit ? "Edit" : "Add"} Text Doc`}
        subName="Please fill out the form"
      />

      {error ? (
        <Flex
          height={"50vh"}
          width={"full"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box width={"200px"}>
            <ErrorCard message={error} />
          </Box>
        </Flex>
      ) : !(loading || createLoading || updateLoading) ? (
        <Container
          borderWidth={"1px"}
          borderRadius={"15px"}
          p={"15px"}
          m={"15px"}
          width={"unset"}
          maxW={"unset"}
          position={"relative"}
          onSubmit={handleSubmit}
          as={"form"}
        >
          <Box
            position={"absolute"}
            right={"1rem"}
            top={"-5rem"}
            textAlign="right"
          >
            <Button px={10} type="submit" colorScheme="teal">
              {isEdit ? "Update" : "Save"}
            </Button>
          </Box>
          <Stack spacing={5}>
            <FormControl>
              <FormLabel mb={0} fontSize={"lg"} htmlFor="bio">
                Title
              </FormLabel>
              <FormHelperText mb={2} mt={0} id="bio-helptext">
                Add title for this doc
              </FormHelperText>
              <Input
                id="title"
                name="title"
                placeholder="add title here"
                value={formData.title}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl>
              <FormLabel mb={0} fontSize={"lg"} htmlFor="bio">
                Captions
              </FormLabel>
              <FormHelperText mb={2} mt={0} id="captions-helptext">
                Add captions here for the text
              </FormHelperText>
              <Input
                id="captions"
                name="captions"
                placeholder="add captions here"
                value={formData.captions}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl>
              <FormLabel mb={0} fontSize={"lg"}>
                Status
              </FormLabel>
              <FormHelperText mb={2} mt={0} id="status-helptext">
                On means this is shared and available for public.
              </FormHelperText>
              <Switch
                colorScheme="teal"
                name="status"
                isChecked={formData.status}
                onChange={handleSwitchChange}
              />
            </FormControl>

            {id && (
              <FormControl>
                <FormLabel mb={0} fontSize={"lg"}>
                  Qr Code
                </FormLabel>
                <FormHelperText mb={2} mt={0} id="status-helptext">
                  Share this qr code and it will display the content only if the
                  status is on
                </FormHelperText>

                <QRCodeGenerator url={qrCodeURL(`t/${id}`)} />
              </FormControl>
            )}

            <FormControl>
              <FormLabel mb={0} fontSize={"lg"} htmlFor="bio">
                Text
              </FormLabel>
              <FormHelperText mb={2} mt={0} id="text-helptext">
                Add text like a memory or anything that describes the text
              </FormHelperText>

              <Box
                p={4}
                border="1px solid"
                borderColor="gray.200"
                borderRadius="md"
                // bg={useColorModeValue("gray.50", "gray.700")}
              >
                <Stack
                  background={"#86868640"}
                  padding={"10px"}
                  flexWrap={"wrap"}
                  borderRadius={"10px"}
                  position={"sticky"}
                  top={"80px"}
                  zIndex={"111"}
                  backdropFilter={"blur(20px)"}
                  margin={"0 10px 14px 10px "}
                  direction="row"
                  spacing={2}
                  mb={4}
                >
                  {toolbarButton(
                    () => toggleBlockType("header-one"),
                    <FaHeading />,
                    "Heading 1"
                  )}
                  {toolbarButton(
                    () => toggleBlockType("header-two"),
                    <FaHeading />,
                    "Heading 2"
                  )}
                  {toolbarButton(
                    () => toggleBlockType("header-three"),
                    <FaHeading />,
                    "Heading 3"
                  )}
                  {toolbarButton(
                    () => toggleInlineStyle("BOLD"),
                    <FaBold />,
                    "Bold"
                  )}
                  {toolbarButton(
                    () => toggleInlineStyle("ITALIC"),
                    <FaItalic />,
                    "Italic"
                  )}
                  {toolbarButton(
                    () => toggleInlineStyle("UNDERLINE"),
                    <FaUnderline />,
                    "Underline"
                  )}
                  {toolbarButton(
                    () => toggleBlockType("unordered-list-item"),
                    <FaListUl />,
                    "Bulleted List"
                  )}
                  {toolbarButton(
                    () => toggleBlockType("ordered-list-item"),
                    <FaListOl />,
                    "Numbered List"
                  )}
                  {toolbarButton(
                    () => toggleBlockType("blockquote"),
                    <FaQuoteRight />,
                    "blockquote"
                  )}
                  {toolbarButton(
                    () => toggleInlineStyle("STRIKETHROUGH"),
                    <BiStrikethrough />,
                    "Strikethrough"
                  )}
                  {toolbarButton(
                    () => toggleInlineStyle("HIGHLIGHT"),
                    <BiHighlight />,
                    "Highlight"
                  )}
                  {toolbarButton(
                    () => toggleBlockType("unstyled"),
                    <FaLink />,
                    "Unstyled"
                  )}
                  {toolbarButton(
                    () => toggleBlockType("indent"),
                    <FaIndent />,
                    "Indent"
                  )}
                  {toolbarButton(
                    () => toggleBlockType("outdent"),
                    <FaOutdent />,
                    "Outdent"
                  )}
                  {toolbarButton(
                    () => toggleBlockType("atomic"),
                    <FaTable />,
                    "Atomic"
                  )}
                  {toolbarButton(undo, <FaUndo />, "Undo")}
                  {toolbarButton(redo, <FaRedo />, "Redo")}
                  {/* <Button
              onClick={exportToMarkdown}
              size="sm"
              variant="outline"
              colorScheme="teal"
            >
              Export to Markdown
            </Button> */}
                </Stack>
                <Box
                  p={2}
                  bg="white"
                  border="1px solid"
                  borderColor="gray.200"
                  background={"transparent"}
                  borderRadius="md"
                >
                  <Editor
                    spellCheck
                    customStyleMap={styleMap}
                    editorState={editorState}
                    handleKeyCommand={handleKeyCommand}
                    onChange={handleEditorChange}
                    placeholder="Type something..."
                  />
                </Box>
              </Box>
            </FormControl>
          </Stack>
          <Divider my={6} />
          <Box textAlign="right">
            <Button px={10} type="submit" colorScheme="teal">
              {isEdit ? "Update" : "Save"}
            </Button>
          </Box>
        </Container>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default AddEditTextPage;

// // <CKEditor
// editor={ClassicEditor}
// data="<p>Hello from CKEditor 5!</p>"
// onReady={(editor) => {
//   console.log(
//     "CKEditor5 React Component is ready to use!",
//     editor
//   );
// }}
// onChange={(event, editor) => {
//   const data = editor.getData();
//   console.log({ event, editor, data });
// }}
// />
// {/* <div
// style={{
//   background: "lightgray",
//   padding: "20px",
//   borderRadius: "10px",
// }}
// >
// </div> */}
// {/* <Editor editorState={editorState} onChange={setEditorState} /> */}
// <CKEditor
// editor={ClassicEditor}
// config={customConfig}
// data="<p>Hello from CKEditor 5!</p>"
// onReady={(editor) => {
//   console.log(
//     "CKEditor5 React Component is ready to use!",
//     editor
//   );
// }}
// onChange={(event, editor) => {
//   const data = editor.getData();
//   console.log({ event, editor, data });
// }}
// />
// {/* ///////////////////////////////////////////////////////////////// */}
