import {
  Box,
  Button,
  Flex,
  Grid,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BiHome } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Loading, NoData } from "../../../components/Common/Components";
import PageHeader from "../../../components/PageHeader";
import {
  deleteLanding,
  getAllLanding,
} from "../../../Redux/action/landing.action";
import AddEditLandingForm from "./AddEditLandingForm";
import LandingCard from "./LandingCard";
import ErrorCard from "../../../components/Common/ErrorCard";
import useDocumentTitle from "../../../interceptor/useDocumentTitle";

const LandingAdminPage = () => {
  useDocumentTitle("Landing");
  const PAGE_SIZE = 6;
  const [data, setData] = useState<any[]>([]); // set initial data
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [landingToDelete, setLandingToDelete] = useState<any>(null);
  const [editLandingId, setEditLandingId] = useState<string | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();
  const toast = useToast();

  const _getAllLanding = useSelector(
    (state: { getAllLanding: any }) => state.getAllLanding
  );
  const { loading: landingLoading, landings, error } = _getAllLanding;

  // create
  const _createLanding = useSelector(
    (state: { createLanding: any }) => state.createLanding
  );
  const { loading: createlandingLoading, landing: createLanding } =
    _createLanding;

  // update
  const _updateLanding = useSelector(
    (state: { updateLanding: any }) => state.updateLanding
  );
  const { loading: updatelandingLoading, landing: updateLanding } =
    _updateLanding;

  const _deleteLanding = useSelector(
    (state: { deleteLanding: any }) => state.deleteLanding
  );
  const { loading: landingDeleteLoading, landing: DeleteLanding } =
    _deleteLanding;

  const toastPositions = useSelector((state: any) => state.toastPosition);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleEdit = (id: string) => {
    setEditLandingId(id);
    onOpen();
  };

  const handleAdd = () => {
    setEditLandingId(null);
    onOpen();
  };

  const handleDelete = (landing: any) => {
    setLandingToDelete(landing);
    setDeleteConfirmationOpen(true);
  };

  const handleConfirmDelete = () => {
    // Perform the delete action here
    if (landingToDelete) {
      dispatch<any>(
        deleteLanding(landingToDelete._id, toastPositions, toast)
      ).then(() => {
        setTimeout(() => {
          dispatch<any>(getAllLanding(toastPositions, toast));
        }, 200);
      });
    }
    // Close the delete confirmation modal
    setDeleteConfirmationOpen(false);
  };

  useEffect(() => {
    dispatch<any>(getAllLanding(toastPositions, toast));
  }, [dispatch, createLanding, updateLanding, DeleteLanding, toast]);

  useEffect(() => {
    const startIndex = (page - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;

    setData(landings);
    setFilteredData(
      data
        ?.filter((d) =>
          d.title?.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .slice(startIndex, endIndex)
    );
  }, [landings, data, landingLoading, searchTerm, page]);

  return (
    <>
      <PageHeader
        onSearchChange={handleSearchChange}
        onButtonClick={handleAdd}
        searchTerm={searchTerm}
        listLength={data?.length}
        name="Landing"
        button={true}
      />

      {error ? (
        <Flex
          height={"50vh"}
          width={"full"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box width={"200px"}>
            <ErrorCard message={error} />
          </Box>
        </Flex>
      ) : (
        (landingLoading ||
          createlandingLoading ||
          updatelandingLoading ||
          landingDeleteLoading) && <Loading />
      )}

      {!(
        error ||
        landingLoading ||
        createlandingLoading ||
        updatelandingLoading ||
        landingDeleteLoading
      ) && filteredData?.length > 0 ? (
        <>
          <Grid templateColumns="repeat(auto-fill, minmax(230px, 1fr))" gap={6}>
            {filteredData?.map((landing) => (
              <LandingCard
                key={landing._id}
                landing={landing}
                onDelete={handleDelete}
                onEdit={handleEdit}
              />
            ))}
          </Grid>

          {data?.length > PAGE_SIZE && (
            <HStack mt={6}>
              {Array.from(
                Array(Math.ceil(data?.length / PAGE_SIZE)),
                (_, i) => i + 1
              ).map((p) => (
                <Button
                  key={p}
                  variant={page === p ? "solid" : "outline"}
                  size="sm"
                  onClick={() => handlePageChange(p)}
                >
                  {p}
                </Button>
              ))}
            </HStack>
          )}
        </>
      ) : (
        !(
          error ||
          landingLoading ||
          createlandingLoading ||
          updatelandingLoading ||
          landingDeleteLoading
        ) && <NoData icon={BiHome} />
      )}

      <Modal
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent
          background={"transparent"}
          borderWidth={"1px"}
          borderRadius={"15px"}
          overflow={"hidden"}
        >
          <Box
            backdropFilter={"blur(20px)"}
            background={useColorModeValue("whiteAlpha.700", "blackAlpha.600")}
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            zIndex={-111}
          ></Box>
          <ModalHeader>
            {editLandingId ? "Edit Landing" : "Add Landing"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddEditLandingForm
              isEdit={!!editLandingId}
              landingId={editLandingId}
              onClose={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* delete */}
      <Modal
        isOpen={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        size="sm"
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          backdropFilter={"blur(20px)"}
          background={useColorModeValue("whiteAlpha.500", "blackAlpha.600")}
          borderWidth={"1px"}
          borderRadius={"15px"}
        >
          <ModalHeader>Delete Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete this product?</Text>
          </ModalBody>
          <ModalFooter gap={"10px"}>
            <Button colorScheme="red" onClick={handleConfirmDelete}>
              Delete
            </Button>
            <Button onClick={() => setDeleteConfirmationOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default LandingAdminPage;
