import { FormControl, Stack } from "@chakra-ui/react";
import React from "react";

interface props {
  children: React.ReactNode;
}

function CustomFormControl({ children }: props) {
  return (
    <FormControl marginTop={"0!important"} id="userName" isRequired>
      <Stack flexDirection={{ base: "column", md: "row" }}>{children}</Stack>
    </FormControl>
  );
}

export default CustomFormControl;
