import MainCarousel from "../components/MainCarousel";
import { Box } from "@chakra-ui/react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { useEffect } from "react";
import useDocumentTitle from "../interceptor/useDocumentTitle";

const HomePage = () => {
  useDocumentTitle("Home");

  return (
    <>
      <Box
        position="relative"
        height="100vh"
        // height="calc(100vh - 0rem)"
        margin={"0rem"}
      >
        <NavBar />

        <MainCarousel />
      </Box>
      <Footer />
    </>
  );
};

export default HomePage;
