import React, { useState, useRef, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Td,
  useColorModeValue,
  useDisclosure,
  Grid,
  Textarea,
  Checkbox,
  useToast,
  Switch,
  Badge,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { IPermission } from "../../../../interface/permission.interface";
import { getActivePermissions } from "../../../../Redux/action/permission.action";
import { truncateWords } from "../../../../util/uitil";
import { IRole } from "../../../../interface/role.interface";

interface RoleTablesRowProps<T> {
  data: T;
  onEdit: (editedData: T) => void;
  onDelete: (id: T) => void;
}
const RoleTablesRow = <T extends IRole>({
  data,
  onEdit,
  onDelete,
}: RoleTablesRowProps<T>) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef<HTMLInputElement | null>(null);
  const finalRef = useRef<HTMLInputElement | null>(null);
  const textColor = useColorModeValue("gray.800", "white");
  const [editingData, setEditingData] = useState<T>({
    ...data,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [pData, setPData] = useState<IPermission[]>([]); // set initial data
  const dispatch = useDispatch();
  const toast = useToast();
  const _getActivePermissions = useSelector(
    (state: { getActivePermissions: any }) => state.getActivePermissions
  );
  const {
    loading: loadingPermissions,
    error,
    permissions,
  } = _getActivePermissions;

  const toastPositions = useSelector((state: any) => state.toastPosition);
  useEffect(() => {
    dispatch<any>(getActivePermissions(toastPositions, toast));
  }, [dispatch, data, toast]);

  useEffect(() => {
    setPData(permissions);
  }, [permissions, pData]);

  const handlePermissionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    if (checked) {
      setEditingData((prevData) => ({
        ...prevData,
        permissions: [
          ...prevData.permissions,
          { _id: value, name: "", details: "", status: true },
        ], // Add permission ID to array
      }));
    } else {
      setEditingData((prevData) => ({
        ...prevData,
        permissions: prevData.permissions.filter(
          (permission: any) => permission._id !== value
        ), // Remove permission ID from array
      }));
    }
  };

  const handleEdit = () => {
    // Handle the edit functionality here
    onEdit(editingData);
    onClose();
  };

  const handleDelete = () => {
    // Close the delete modal and call the onDelete callback
    setShowDeleteModal(false);
    onDelete(data);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;
    if (type === "checkbox") {
      const isChecked = (e.target as HTMLInputElement).checked;
      setEditingData((prevData: any) => ({
        ...prevData,
        [name]: isChecked,
      }));
    } else
      setEditingData((prevData: any) => ({
        ...prevData,
        [name]: value,
      }));
  };

  return (
    <>
      <Td pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text
            fontSize="md"
            color={textColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {data.name}
          </Text>
        </Flex>
      </Td>

      <Td pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {truncateWords(data.details, 45)}
          </Text>
        </Flex>
      </Td>

      <Td pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text
            fontSize="md"
            color={textColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {data.permissions?.length}
          </Text>
        </Flex>
      </Td>

      <Td>
        <Badge
          bg={data.isDefault === true ? "green.400" : "red.400"}
          color={data.isDefault === true ? "white" : "white"}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {data.isDefault ? "Default" : "Not-Default"}
        </Badge>
      </Td>

      <Td>
        <Badge
          bg={data.status === true ? "green.400" : "red.400"}
          color={data.status === true ? "white" : "white"}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {data.status ? "Active" : "InActive"}
        </Badge>
      </Td>

      <Td>
        <Grid gridAutoFlow={"column"} gap={"10px"}>
          <Button
            onClick={() => {
              setEditingData({
                ...data,
              });
              onOpen();
            }}
            variant="outline"
            colorScheme="purple"
            py="2px"
          >
            Edit
          </Button>
          <Button
            onClick={() => setShowDeleteModal(true)}
            variant="outline"
            colorScheme="red"
            py={"2px"}
            px={"30px"}
          >
            <Text fontSize="md" fontWeight="bold" cursor="pointer">
              Delete
            </Text>
          </Button>
        </Grid>
      </Td>

      {/* edit modal */}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="md"
      >
        <ModalOverlay />
        <ModalContent
          backdropFilter={"blur(20px)"}
          background={useColorModeValue("whiteAlpha.800", "blackAlpha.600")}
          borderWidth={"1px"}
          borderRadius={"15px"}
        >
          <ModalHeader>
            <Flex justify="space-between">
              <Flex align="center" gap="4">
                <Avatar name={editingData.name} src={editingData.name} />
                <Box>
                  <Heading size="sm">{editingData.name}</Heading>
                </Box>
              </Flex>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                defaultValue={editingData.name}
                onChange={handleInputChange}
                name="name"
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Description</FormLabel>
              <Textarea
                defaultValue={editingData.details}
                onChange={handleInputChange}
                name="description"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Is Default</FormLabel>
              <Switch
                colorScheme="green"
                name="isDefault"
                isChecked={editingData.isDefault}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Status</FormLabel>
              <Switch
                colorScheme="green"
                name="status"
                isChecked={editingData.status}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Permissions</FormLabel>
              <Grid margin={"5px"} gap={"10px"}>
                {pData?.map((permission: IPermission) => (
                  <Checkbox
                    key={permission._id}
                    name={"permissions"}
                    value={permission._id}
                    onChange={handlePermissionChange}
                    isChecked={editingData.permissions?.some(
                      (item: IPermission) => item._id === permission._id
                    )}
                  >
                    <Flex flexDir={"column"}>
                      <Text textTransform={"uppercase"} fontWeight={600} mr={4}>
                        {" "}
                        {permission.name}
                      </Text>
                      <Text color={"gray.400"}> {permission.details}</Text>
                    </Flex>
                  </Checkbox>
                ))}
              </Grid>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme={"green"} mr={3} onClick={handleEdit}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* delete modal  */}
      <Modal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        size="sm"
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          backdropFilter={"blur(20px)"}
          background={useColorModeValue("whiteAlpha.500", "blackAlpha.600")}
          borderWidth={"1px"}
          borderRadius={"15px"}
        >
          <ModalHeader>Delete Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete this product?</Text>
          </ModalBody>
          <ModalFooter gap={"10px"}>
            <Button colorScheme="red" onClick={handleDelete}>
              Delete
            </Button>
            <Button onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RoleTablesRow;
