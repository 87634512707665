import {
  Button,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from "@chakra-ui/react";
import FormStack from "./Components/FormStack";
import ChangePWD from "./Components/ChangePWD";
import { UnlockIcon } from "@chakra-ui/icons";
import LockCard from "./Components/LockCard";
import OverView2 from "./Components/OverView2";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { executeOnFirstRender } from "../../../interceptor/ExcuteOnFirstCall";
import { resetGetLoggedInUserState } from "../../../Redux/action/user.action";
import useDocumentTitle from "../../../interceptor/useDocumentTitle";

function Profile() {
  useDocumentTitle("Profile");
  const loggedInUser = useSelector(
    (state: { loggedInUser: any }) => state.loggedInUser
  );
  const { userInfo } = loggedInUser;

  const dispatch = useDispatch();
  const location = useLocation();
  const shouldLog = useRef(true);

  const toast = useToast();

  useEffect(() => {
    executeOnFirstRender(shouldLog, () => {
      lockHandler();
    });
  }, [dispatch, location]);

  const lockHandler = () => {
    // console.log(userInfo)
    dispatch(resetGetLoggedInUserState());
    toast({
      title: "Account Locked !",
      status: "warning",
      duration: 5000,
      position: "top",
      isClosable: true,
    });
  };

  return (
    <>
      {userInfo ? (
        <Tabs variant="soft-rounded" colorScheme="purple">
          <TabList justifyContent={"space-between"}>
            <Stack flexDirection={"row"}>
              <TabComponet text="Overview" />
              <TabComponet text="Edit" />
              <TabComponet text="Change Password" />
            </Stack>
            <Button
              onClick={lockHandler}
              colorScheme="teal"
              // _selected={{ color: "white", bg:  }}
            >
              <UnlockIcon marginRight={"10px"} /> <span>Lock Account</span>
            </Button>
          </TabList>
          <TabPanels>
            <TabPanel my={"15px"}>
              <OverView2 />
            </TabPanel>

            <TabPanel my={"15px"}>
              <FormStack />
            </TabPanel>

            <TabPanel my={"15px"}>
              <ChangePWD />
            </TabPanel>
          </TabPanels>
        </Tabs>
      ) : (
        <LockCard />
      )}
    </>
  );
}

export default Profile;

//

interface props {
  text: string;
}
function TabComponet({ text }: props) {
  return (
    <Tab
      margin={"0 2px !important"}
      _selected={{ color: "white", bg: "teal.400" }}
    >
      {text}
    </Tab>
  );
}
// linear-gradient(rgb(0 0 0 / 50%), rgb(0 0 0 / 50%)) ,url(https://wallpaperaccess.com/full/4268828.png)
