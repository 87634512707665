import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import {
  Box,
  BoxProps,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  Grid,
  Icon,
  IconButton,
  Image,
  Link,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  FaGooglePlus,
  FaHome,
  FaTradeFederation,
  FaUserEdit,
  FaUsers,
} from "react-icons/fa";
import { BsGear, BsOption } from "react-icons/bs";
import { IconType } from "react-icons";
import {
  BiHome,
  BiKey,
  BiPhotoAlbum,
  BiShield,
  BiText,
  BiVideo,
} from "react-icons/bi";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import logo from "./../assets/makigifts-logo-header.png";
import Footer from "./Footer";
import { useSelector } from "react-redux";
import { SignedInMenu } from "./SignedInMenu";

const SigendInLayout = ({ children }: { children: React.ReactNode }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();
  const toast = useToast();
  const signUp = useSelector((state: { signUp: any }) => state.signUp);
  const { loading, error, userInfo } = signUp;

  useEffect(() => {
    // Check if userInfo exists (user is logged in)
    if (!userInfo) {
      navigate("/sign-in");
    }
    if (loading) {
      toast({
        title: "Loading ...",
        status: "loading",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
    }
    if (error) {
      toast({
        title: error,
        status: "error",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [navigate, userInfo]);

  return (
    <>
      <Grid>
        <Flex
          marginLeft={{ base: 0, md: "16rem!important" }}
          py={2}
          borderRadius="10px"
          boxShadow="md"
          position="sticky"
          top={3}
          m={"15px"}
          backdropFilter={"blur(10px)"}
          bg={useColorModeValue("whiteAlpha.200", "whiteAlpha.200")}
          color={useColorModeValue("gray.600", "white")}
          minH={"50px"}
          zIndex={"111"}
          px={{ base: 4 }}
          borderBottom={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.900")}
          align={"center"}
          justifyContent={"space-between"}
        >
          <Flex alignItems={"center"}>
            <IconButton
              mr={"10px"}
              display={{ base: "flex", md: "none" }}
              onClick={onOpen}
              colorScheme="teal"
              aria-label="open menu"
              icon={<BsOption />}
            />
            <Box display={{ base: "block", md: "none" }}>
              <RouterLink to={"/"}>
                <Image src={logo} alt="maki gifts Logo" height="50px" />
              </RouterLink>
            </Box>
          </Flex>
          <Box display={"flex"} alignItems={"center"} gap={"10px"}>
            <ColorModeSwitcher justifySelf="flex-end" />
            <SignedInMenu
              isAdmin={userInfo?.userInfo?.role?.name === "Admin"}
            />
          </Box>
        </Flex>

        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
          isUser={false}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="xs"
        >
          <DrawerContent>
            <SidebarContent isUser={false} onClose={onClose} />
          </DrawerContent>
        </Drawer>

        <Box
          marginLeft={{ base: 0, md: "15rem" }}
          // marginTop={{ base: "4rem", md: "0" }}
          //   width={"full"}
          mb={"2rem"}
          minHeight={"81vh"}
          paddingX={"20px"}
        >
          {children}
        </Box>
        <Box paddingLeft={{ base: 0, md: "15rem!important" }}>
          <Footer />
        </Box>
      </Grid>
    </>
  );
};

export default SigendInLayout;

//////////////////////////////////////////////////////////////////

interface LinkItemProps {
  name: string;
  icon: IconType;
  link: string;
  isAdmin?: boolean;
  category?: string;
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
  isUser?: boolean;
}
const SidebarContent = ({ onClose, isUser, ...rest }: SidebarProps) => {
  const LinkItems: Array<LinkItemProps> = [
    {
      name: "Dashboard",
      icon: FaHome,
      link: "/v1/",
      isAdmin: false,
      category: "Main",
    },
    {
      name: "Videos",
      icon: BiVideo,
      link: `/v1/video`,
      isAdmin: false,
      category: "Share",
    },
    { name: "Picture", icon: BiPhotoAlbum, link: `/v1/picture` },
    { name: "Text", icon: BiText, link: `/v1/text` },
    {
      name: "Landing",
      icon: BiHome,
      link: `/v1/landing`,
      category: "Contents",
    },
    { name: "AboutUs", icon: FaTradeFederation, link: `/v1/about-us` },
    { name: "Social Links", icon: FaGooglePlus, link: `/v1/social` },
    { name: "Users", icon: FaUsers, link: `/v1/users`, category: "Admin" },
    { name: "Role", icon: BiShield, link: `/v1/role` },
    { name: "Permission", icon: BiKey, link: `/v1/permission` },
    {
      name: "Profile",
      icon: FaUserEdit,
      link: "/v1/profile",
      category: "Setting",
    },
    // { name: "Help", icon: BiHelpCircle, link: "/v1/helps" },
    { name: "Setting", icon: BsGear, link: "/v1/setting" },
  ];

  const filteredLinks = isUser
    ? LinkItems.filter((link) => !link.isAdmin)
    : LinkItems;

  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.800")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="center">
        <Link
          as={RouterLink}
          to={`/v1`}
          fontSize={"15px"}
          display={"flex"}
          fontWeight={"bold"}
          alignItems={"center"}
        >
          <Image src={logo} alt="maki gifts Logo" height="50px" />
        </Link>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <Box paddingX={"5px"} height={"calc(80% - 20px)"} overflow={"auto"}>
        <>
          {filteredLinks.map((link, index) => (
            <div key={index}>
              {
                <Box
                  marginLeft={"3px"}
                  fontSize={"12px"}
                  fontWeight={"semibold"}
                >
                  {link.category}
                </Box>
              }
              <NavItem key={index} link={link.link} icon={link.icon}>
                {link.name}
              </NavItem>
            </div>
          ))}
        </>
      </Box>
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: string | number;
  link: string;
}
const NavItem = ({ icon, link, children, ...rest }: NavItemProps) => {
  const location = useLocation();

  // Check if the link is active
  const isActive = location.pathname === link;
  // const isActive = location.pathname.includes(link);

  return (
    <RouterLink to={link}>
      <Link style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }}>
        <Flex
          align="center"
          p="8px"
          m="6px"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          fontWeight={"normal"}
          fontSize={"14px"}
          bg={isActive ? "teal.500" : undefined}
          color={isActive ? "white" : undefined}
          _hover={{
            bg: "teal.500",
            color: "white",
          }}
          {...rest}
        >
          {icon && (
            <Icon
              mr="4"
              fontSize="20px"
              _groupHover={{
                color: "white",
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      </Link>
    </RouterLink>
  );
};
