import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { Badge, Box, IconButton, Stack, Text } from "@chakra-ui/react";
import { BiVideo, BiVideoOff } from "react-icons/bi";
import { truncateWords } from "../../../util/uitil";
import { Link } from "react-router-dom";

interface VideoCardProps {
  video: any;
  onDelete: (video: any) => void;
}

const VideoCard: React.FC<VideoCardProps> = ({ video, onDelete }) => {
  return (
    <Box
      backgroundSize={"cover !important"}
      background={`linear-gradient(360deg, rgb(0 0 0 ), rgba(0, 0, 0, 0) 100%) `}
      minWidth="200px"
      minHeight="300px"
      position="relative"
      borderRadius="lg"
      boxShadow="lg"
      overflow={"hidden"}
    >
      <video
        src={video.video}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "relative",
          zIndex: -1,
        }}
      />
      <Box p={4}>
        <Badge
          backgroundColor={video.status ? "#4fd1c5" : "#d14f70"}
          position={"absolute"}
          right={"10px"}
          top={"10px"}
          zIndex={1}
          borderRadius="full"
          color={"black"}
          px="2"
        >
          {video.status ? "Active" : "Deactive"}
        </Badge>

        <Box
          position="absolute"
          top="10px"
          left="10px"
          zIndex={1}
          color="white"
        >
          {video.video ? <BiVideo size="24px" /> : <BiVideoOff size="24px" />}
        </Box>

        <Stack
          direction="column"
          spacing={2}
          position="absolute"
          bottom="30px"
          right="13px"
          zIndex={1}
        >
          <IconButton
            icon={<EditIcon />}
            aria-label="Edit"
            variant="solid"
            colorScheme="blue"
            size="sm"
            as={Link}
            to={`edit/${video._id}`}
          />
          <IconButton
            icon={<DeleteIcon />}
            aria-label="Delete"
            variant="solid"
            colorScheme="red"
            size="sm"
            onClick={() => onDelete(video)}
          />
        </Stack>

        <Box position="absolute" bottom={4} left={1} right={4} p={3}>
          <Text fontSize="xl" fontWeight="bold" color="white" mb={1}>
            {truncateWords(video.title, 15)}
          </Text>
          <Text color="gray.300" fontSize="md" noOfLines={2}>
            {truncateWords(video.text, 50)}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default VideoCard;
