import { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import AddPermissionForm from "./components/AddPermissionForm";
import { IPermission } from "../../../interface/permission.interface";
import {
  createPermission,
  deletePermission,
  getAllPermissions,
  updatePermission,
} from "../../../Redux/action/permission.action";
import CommonTable from "../../../components/Common/CommonTable";
import PermissionTablesRow from "./components/PermissionTablesRow";
import useDocumentTitle from "../../../interceptor/useDocumentTitle";

const PermissionPage = () => {
  useDocumentTitle("Permission");

  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();

  const _getAllPermissions = useSelector(
    (state: { getAllPermissions: any }) => state.getAllPermissions
  );
  const {
    loading: loadingPermissions,
    error,
    permissions,
  } = _getAllPermissions;

  const _updatePermission = useSelector(
    (state: { updatePermission: any }) => state.updatePermission
  );
  const { loading: editLoading, permission } = _updatePermission;

  const _deletePermission = useSelector(
    (state: { deletePermission: any }) => state.deletePermission
  );
  const { loading: deleteLoading, permission: deleteSuccess } =
    _deletePermission;

  const _createPermission = useSelector(
    (state: { createPermission: any }) => state.createPermission
  );
  const { loading: disableLoading, permission: createSuccess } =
    _createPermission;

  const toastPositions = useSelector((state: any) => state.toastPosition);

  useEffect(() => {
    dispatch<any>(getAllPermissions(toastPositions, toast));
  }, [dispatch, deleteSuccess, permission, createSuccess, toast]);

  const handleAddButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleFormSubmit = () => {
    // Handle form submission here
    setShowModal(false);
  };

  const handleAddPermission = (pData: any) => {
    dispatch<any>(
      createPermission(
        { name: pData.name, details: pData.details, status: pData.status },
        toastPositions,
        toast,
        () => setShowModal(false)
      )
    );
  };

  return (
    <>
      <CommonTable
        title="Permissions"
        color="transparent"
        caption={["Name", "Description", "Actions"]}
        tableData={permissions}
        pageSize={6}
        handleAddButtonClick={handleAddButtonClick}
        handleCloseModal={handleCloseModal}
        handleFormSubmit={handleFormSubmit}
        showModal={showModal}
        error={error}
        loading={
          loadingPermissions || editLoading || deleteLoading || disableLoading
        }
        formComponent={
          <AddPermissionForm
            closeModal={handleCloseModal}
            onSubmit={handleAddPermission}
          />
        }
        renderRow={(permission: IPermission) => (
          <PermissionTablesRow<IPermission>
            data={permission}
            onEdit={(editedPermission: IPermission) => {
              dispatch<any>(
                updatePermission(
                  editedPermission?._id,
                  {
                    name: editedPermission?.name,
                    details: editedPermission.details,
                    status: editedPermission.status,
                  },

                  toastPositions,
                  toast,
                  () => setShowModal(false)
                )
              );
            }}
            onDelete={(deletedPermission: IPermission) => {
              dispatch<any>(
                deletePermission(deletedPermission?._id, toastPositions, toast)
              );
            }}
          />
        )}
      />
    </>
  );
};

export default PermissionPage;
