import { Box, Heading, Text, Link } from "@chakra-ui/react"; 
import useDocumentTitle from "../interceptor/useDocumentTitle";
import NonLoginLayout from "../components/NonLoginLayout";

const TermsOfService = () => {
  useDocumentTitle("Term Of Service");

  return (
    <NonLoginLayout>
      <Box mt={"20px"} p={8}>
        <Heading as="h1" size="xl" mb={4}>
          Terms of Service
        </Heading>

        <Text fontSize="lg" mb={6}>
          Please read these Terms of Service ("Terms", "Terms of Service")
          carefully before using the Maki Gifts website (the "Service") operated
          by Maki Gifts ("us", "we", or "our").
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          Agreement to Terms
        </Heading>

        <Text fontSize="lg" mb={6}>
          By accessing or using the Service, you agree to be bound by these
          Terms. If you disagree with any part of the terms, then you may not
          access the Service.
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          Termination
        </Heading>

        <Text fontSize="lg" mb={6}>
          We may terminate or suspend access to our Service immediately, without
          prior notice or liability, for any reason whatsoever, including
          without limitation if you breach the Terms.
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          User Responsibilities
        </Heading>

        <Text fontSize="lg" mb={6}>
          You are responsible for maintaining the confidentiality of your
          account and password and for restricting access to your computer or
          device. You agree to accept responsibility for all activities that
          occur under your account or password.
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          Intellectual Property
        </Heading>

        <Text fontSize="lg" mb={6}>
          The Service and its original content, features, and functionality are
          owned by Maki Gifts and are protected by international copyright,
          trademark, patent, trade secret, and other intellectual property or
          proprietary rights laws.
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          Limitation of Liability
        </Heading>

        <Text fontSize="lg" mb={6}>
          In no event shall Maki Gifts, nor its directors, employees, partners,
          agents, suppliers, or affiliates, be liable for any indirect,
          incidental, special, consequential, or punitive damages, including
          without limitation, loss of profits, data, use, goodwill, or other
          intangible losses, resulting from (i) your access to or use of or
          inability to access or use the Service; (ii) any conduct or content of
          any third party on the Service; (iii) any content obtained from the
          Service; and (iv) unauthorized access, use, or alteration of your
          transmissions or content, whether based on warranty, contract, tort
          (including negligence), or any other legal theory, whether or not we
          have been informed of the possibility of such damage, and even if a
          remedy set forth herein is found to have failed of its essential
          purpose.
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          Governing Law
        </Heading>

        <Text fontSize="lg" mb={6}>
          These Terms shall be governed and construed in accordance with the
          laws of [Your Country], without regard to its conflict of law
          provisions.
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          Contact Us
        </Heading>

        <Text fontSize="lg" mb={6}>
          If you have any questions about these Terms, please contact us at{" "}
          <Link color={"teal.400"} href="mailto:contact@makigifts.com">
            contact@makigifts.com
          </Link>
        </Text>
      </Box>
    </NonLoginLayout>
  );
};

export default TermsOfService;
