import {
  useToast,
  Stack,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Button,
  Switch,
  ModalFooter,
  Flex,
  Box,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../../components/Common/Components";
import {
  createSocialLinks,
  getSocialLinksById,
  updateSocialLinks,
} from "../../../Redux/action/socialLinks.action";
import ErrorCard from "../../../components/Common/ErrorCard";

interface AddEditSocialFormProps {
  isEdit: boolean;
  socialId: string | null;
  onClose: () => void;
}

const AddEditSocialForm: React.FC<AddEditSocialFormProps> = ({
  isEdit,
  socialId,
  onClose,
}) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [formData, setFormData] = useState({
    name: "",
    icon: "",
    color: "",
    link: "",
    status: false,
  });

  const _socialLinksById = useSelector(
    (state: { socialLinksById: any }) => state.socialLinksById
  );
  const { loading: socialLoading, socialLink, error } = _socialLinksById;

  const toastPositions = useSelector((state: any) => state.toastPosition);

  useEffect(() => {
    if (isEdit && socialId) {
      dispatch<any>(getSocialLinksById(socialId, toastPositions, toast));
    }
  }, [dispatch, socialId, isEdit]);

  useEffect(() => {
    if (isEdit && socialLink) {
      setFormData({
        name: socialLink.name,
        icon: socialLink.icon,
        color: socialLink.color,
        link: socialLink.link,
        status: socialLink.status,
      });
    }
  }, [socialLink, isEdit]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: checked }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isEdit) {
      dispatch<any>(
        updateSocialLinks(
          socialId ? socialId : "",
          formData,
          toastPositions,
          toast,
          onClose
        )
      );
    } else {
      dispatch<any>(
        createSocialLinks(formData, toastPositions, toast, onClose)
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && !socialLoading && (
        <Flex
          height={"50vh"}
          width={"full"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box width={"200px"}>
            <ErrorCard message={error} />
          </Box>
        </Flex>
      )}

      {!error && !socialLoading ? (
        <Stack spacing={5}>
          <FormControl>
            <FormLabel mb={0} fontSize="lg" htmlFor="name">
              Name
            </FormLabel>
            <FormHelperText mb={2} mt={0} id="name-helptext">
              Add name for this social link
            </FormHelperText>
            <Input
              id="name"
              name="name"
              placeholder="add name here"
              value={formData.name}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl>
            <FormLabel mb={0} fontSize="lg" htmlFor="icon">
              Icon
            </FormLabel>
            <FormHelperText mb={2} mt={0} id="icon-helptext">
              Add icon class for this social link
            </FormHelperText>
            <Input
              id="icon"
              name="icon"
              placeholder="add icon class here"
              value={formData.icon}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl>
            <FormLabel mb={0} fontSize="lg" htmlFor="color">
              Color
            </FormLabel>
            <FormHelperText mb={2} mt={0} id="color-helptext">
              Add color code for this social link
            </FormHelperText>
            <Input
              id="color"
              name="color"
              placeholder="add color code here"
              value={formData.color}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl>
            <FormLabel mb={0} fontSize="lg" htmlFor="link">
              Link
            </FormLabel>
            <FormHelperText mb={2} mt={0} id="link-helptext">
              Add link for this social profile
            </FormHelperText>
            <Input
              id="link"
              name="link"
              placeholder="add link here"
              value={formData.link}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl>
            <FormLabel mb={0} fontSize="lg">
              Status
            </FormLabel>
            <FormHelperText mb={2} mt={0} id="status-helptext">
              On means this link is active and available.
            </FormHelperText>
            <Switch
              colorScheme="teal"
              name="status"
              isChecked={formData.status}
              onChange={handleSwitchChange}
            />
          </FormControl>
        </Stack>
      ) : (
        <Loading />
      )}

      <ModalFooter>
        <Button mr={3} px={"25px"} type="submit" colorScheme="teal">
          Save
        </Button>
        <Button onClick={onClose}>Close</Button>
      </ModalFooter>
    </form>
  );
};

export default AddEditSocialForm;
