import { AddIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import React from "react";

interface PageHeaderProps {
  name: string;
  subName?: string;
  listLength?: number;
  searchTerm?: string;
  button?: boolean;
  onSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onButtonClick?: () => void;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  name,
  searchTerm,
  listLength,
  button,
  onSearchChange,
  onButtonClick,
  subName,
}) => {
  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          {/*  */}
          <Heading as="h1" fontWeight="normal" size="xl" mt="4" mb="0">
            {name}s{" "}
            {listLength ? (
              <Badge
                fontSize="xl"
                p={"5px"}
                borderRadius={"full"}
                colorScheme="purple"
              >
                {listLength}
              </Badge>
            ) : null}
          </Heading>
          <Text display="flex" ml="2px" mb="4">
            {subName ? (
              subName
            ) : (
              <>
                Here are list of{" "}
                <Text
                  as="span"
                  borderBottom="1px solid #48bb78"
                  fontWeight="600"
                  mb={1}
                  mx="5px"
                  maxWidth="max-content"
                >
                  {name.toLowerCase()}
                </Text>{" "}
              </>
            )}
          </Text>
        </Box>
        <Flex alignItems="center">
          {(onSearchChange || searchTerm) && (
            <Input
              placeholder="Search"
              value={searchTerm}
              onChange={onSearchChange}
              mr={2}
            />
          )}
          {onButtonClick && (
            <Button
              mt={0}
              paddingX={"20px"}
              colorScheme="teal"
              leftIcon={<AddIcon />}
              onClick={onButtonClick}
            >
              Create
            </Button>
          )}
        </Flex> 
      </Flex>
    </>
  );
};
export default PageHeader;
