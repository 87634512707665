import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import { useAnimation } from "framer-motion";
import { GradientBackgrounds } from "../components/MainCarousel";
import { Carousel } from "react-responsive-carousel";
import { useParams } from "react-router-dom";
import { truncateWords } from "../util/uitil";
import SocialMedia from "../components/SocialMedia";
import { useDispatch, useSelector } from "react-redux";
import { getActiveSharePictureById } from "../Redux/action/picture.action";
import ErrorCard from "../components/Common/ErrorCard";
import { Loading } from "../components/Common/Components";
import Footer from "../components/Footer";
import useDocumentTitle from "../interceptor/useDocumentTitle";

const PicturePage = () => {
  const [seeMore, setSeeMore] = useState(true);
  const controls = useAnimation();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const toast = useToast();

  const { loading, sharePicture, error } = useSelector(
    (state: { activeSharePicture: any }) => state.activeSharePicture
  );

  useDocumentTitle(sharePicture?.title);

  const toastPositions = useSelector((state: any) => state.toastPosition);

  useEffect(() => {
    if (id) {
      dispatch<any>(getActiveSharePictureById(id, toastPositions, toast));
    }
  }, [dispatch, id]);

  const handleSeeMore = () => {
    setSeeMore(!seeMore);
  };

  // Use the useEffect hook to animate the text elements on mount
  useEffect(() => {
    controls.start("visible");
  }, [controls]);

  return (
    <>
      <Box height="100vh" position="relative">
        <NavBar />
        {!loading && !error ? (
          <Carousel
            stopOnHover={true}
            // emulateTouch={true}
            autoFocus
            verticalSwipe="standard"
            autoPlay={true}
            interval={4000}
            infiniteLoop
            showArrows={true}
            showStatus={false}
            showIndicators={true}
            thumbWidth={3}
            dynamicHeight={false}
            showThumbs={false}
            // centerSlidePercentage={90}
            // centerMode
          >
            {sharePicture?.pictures?.map((picture: any, index: any) => (
              <Box
                // mx={3}
                position="relative"
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                // overflow="hidden"
                // borderRadius={{ base: "30px", sm: "15px", md: "30px" }}
              >
                <GradientBackgrounds
                  type="PicturePage"
                  key={index}
                  image={picture}
                />

                <Box
                  p={"6rem 1rem 2rem 1rem "}
                  position="absolute"
                  bottom="0rem"
                  left="0"
                  right="0"
                  background={
                    "linear-gradient(0deg, black 0%, transparent 99%)"
                  }
                >
                  <Flex
                    flexDirection="column"
                    alignItems="flex-start"
                    zIndex="2"
                    width="100%"
                    color="white"
                  >
                    {/* Text and Timestamp Adjuster */}
                    <Box textAlign={"start"} mb="0.5rem">
                      <Text fontWeight="bold" fontSize="25px">
                        {sharePicture.title}
                      </Text>
                      <Flex
                        width={"100%"}
                        // align={"center"}
                        justify={"space-between"}
                        alignItems={"flex-end"}
                      >
                        <Text
                          maxHeight={"55vh"}
                          overflowY={"auto"}
                          width={
                            sharePicture?.text?.length >= 130
                              ? "calc(100% - 100px)"
                              : "100%"
                          }
                          fontSize="16px"
                          zIndex={"1111111111"}
                          sx={{
                            // Custom scrollbar styles
                            "&::-webkit-scrollbar": {
                              borderRadius: "8px",
                              width: "8px",
                            },
                            "&::-webkit-scrollbar-track": {
                              borderRadius: "8px",
                              background: "#f1f1f1",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              background: "#888",
                              borderRadius: "8px",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                              borderRadius: "8px",
                              background: "#555",
                            },
                          }}
                        >
                          {truncateWords(
                            sharePicture.text,
                            seeMore ? 130 : 200000000
                          )}
                        </Text>
                        {sharePicture?.text?.length >= 130 && (
                          <Text
                            //   width={"100px"}
                            onClick={handleSeeMore}
                            color={"blue.400"}
                            cursor={"pointer"}
                            as={"a"}
                          >
                            See {!seeMore ? "Less" : "More"}
                          </Text>
                        )}
                      </Flex>
                      <Text color="teal.400" fontSize="sm">
                        {sharePicture.captions}
                      </Text>
                    </Box>
                  </Flex>

                  {/* social m */}
                  <SocialMedia
                    width={"100%"}
                    justifyContent={"center"}
                    color={"white"}
                  />
                </Box>
              </Box>
            ))}
          </Carousel>
        ) : error ? (
          <Flex
            height="50vh"
            width="full"
            alignItems="center"
            justifyContent="center"
          >
            <Box width="200px">
              <ErrorCard message={error} />
            </Box>
          </Flex>
        ) : (
          <Loading />
        )}
      </Box>
      <Footer />
    </>
  );
};

export default PicturePage;
