import { useEffect } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  HStack,
  Button,
  useBreakpointValue,
  useColorModeValue,
  Heading,
  Stack,
  Link,
  useToast,
} from "@chakra-ui/react";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import logo from "./../assets/makigifts-logo-header.png";
import { BiPhoneCall } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getAllActiveSocialLinks } from "../Redux/action/socialLinks.action";
import ErrorCard from "../components/Common/ErrorCard";
import { Loading } from "../components/Common/Components";
import { getIconComponent } from "./Admin/SocialLinks/SocialAdminPage";
import QRCodeGenerator from "../components/QRCode";
import useDocumentTitle from "../interceptor/useDocumentTitle";
import NonLoginLayout from "../components/NonLoginLayout";

const ContactUsPage = () => {
  useDocumentTitle("Contact Us");
  const isMobile = useBreakpointValue({ base: true, md: false });

  const dispatch = useDispatch();
  const toast = useToast();

  const _allActiveSocialLinks = useSelector(
    (state: { allActiveSocialLinks: any }) => state.allActiveSocialLinks
  );
  const { loading, socialLinks, error } = _allActiveSocialLinks;

  const toastPositions = useSelector((state: any) => state.toastPosition);

  useEffect(() => {
    dispatch<any>(getAllActiveSocialLinks(toastPositions, toast));
  }, [dispatch, toast]);

  return (
    <NonLoginLayout>
      <Flex
        borderWidth={"1px"}
        borderColor={"teal.400"}
        as={Box}
        id="contact"
        marginTop="20px !important"
        margin={"15px"}
        marginBottom={"3rem !important"}
        direction={{ base: "column", md: "row" }}
        align="center"
        justify="center"
        borderRadius={30}
        overflow="hidden"
        height={{ base: "", md: "100vh" }}
        minHeight={{ base: "44rem", md: "44rem" }}
        background={useColorModeValue("gray.100", "gray.800")}
        boxShadow={"md"}
      >
        <Box
          w={{ base: "100%", md: "50%" }}
          bgSize="cover"
          bgRepeat="no-repeat"
          bgPosition="bottom !important"
          background={`linear-gradient(360deg, rgb(0, 0, 0), rgba(0, 0, 0, 0) 100%) center bottom / cover no-repeat, url(https://images.pexels.com/photos/5821029/pexels-photo-5821029.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500)`}
          h="100%"
          minH={"30rem"}
          p={6}
          display={"grid"}
          alignContent={"end"}
          color={"white"}
          pos={"relative"}
        >
          <Image
            src={logo}
            alt="Logo"
            width={"100px"}
            objectFit={"cover"}
            mb={4}
            pos={"absolute"}
            top={"10px"}
            left={"10px"}
          />

          <Box pos={"absolute"} top={"10px"} right={"10px"}>
            <QRCodeGenerator
              width={"135px"}
              height={"135px"}
              overflow={"hidden"}
              borderRadius={"10px"}
              size={120}
              logoWidth={40}
              button={true}
              url={window.location.href}
            />
          </Box>

          <Heading as="h2" size="lg" mb={0}>
            Contact Us
          </Heading>
          <Text fontWeight={"light"} mt={"10px"} mb={6}>
            At Maki Gifts, we believe in the power of thoughtful gifting.
            Whether you are looking for a unique custom gift to celebrate a
            special moment or a timeless regular gift to show your appreciation,
            we are here to help. Our team is passionate about creating memorable
            experiences through personalized and carefully curated gifts. We'd
            love to hear from you and discuss how we can bring your gifting
            vision to life.
          </Text>

          <Stack spacing={4}>
            <Box>
              <Flex align="center">
                <BiPhoneCall size={24} color={"white"} />
                <Text ml={2}>
                  Phone:{" "}
                  <Link color={"teal.400"} href={`tel:+251904023940`}>
                    +251 904023940
                  </Link>
                </Text>
              </Flex>
            </Box>
          </Stack>
        </Box>

        <Box
          padding={{ base: "50px 30px", md: "50px" }}
          w={{ base: "100%", md: "50%" }}
        >
          <Heading as="h2" fontWeight={"normal"} size="2xl">
            Say Hi!
          </Heading>
          <Text fontWeight={"light"} mt={"10px"} mb={4}>
            We'd like to talk with you
          </Text>

          <Flex direction="column" justifyContent="center" alignItems="center">
            {!loading && !error ? (
              <>
                {socialLinks.map((media: any, index: number) => (
                  <Flex
                    key={index}
                    justifyContent="space-between"
                    alignItems="center"
                    borderColor={media?.color}
                    borderWidth={"2px"}
                    p={6}
                    borderRadius="lg"
                    w="100%"
                    mb={4}
                    textAlign="center"
                  >
                    <HStack spacing={4}>
                      {getIconComponent(media?.icon, media?.color)}
                      {/* <Box as={media.icon} boxSize={"50px"} /> */}
                      <Text fontSize="lg" fontWeight="bold">
                        {media?.name}
                      </Text>
                    </HStack>
                    <Button
                      colorScheme={"teal"}
                      bg={media.color}
                      variant="solid"
                      as={Link}
                      target="_blank"
                      href={media?.link}
                    >
                      Contact Us
                    </Button>
                  </Flex>
                ))}
              </>
            ) : error ? (
              <Flex
                height="50vh"
                width="full"
                alignItems="center"
                justifyContent="center"
              >
                <Box width="200px">
                  <ErrorCard message={error} />
                </Box>
              </Flex>
            ) : (
              <Loading />
            )}
          </Flex>
        </Box>
      </Flex>
    </NonLoginLayout>
  );
};

export default ContactUsPage;
