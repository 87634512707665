import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import {
  useToast,
  Stack,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Textarea,
  Flex,
  Button,
  Switch,
  ModalFooter,
  Text,
  Box,
  Image,
  Progress,
  Center,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../../components/Common/Components";
import {
  createSharePicture,
  getSharePictureById,
  updateSharePicture,
} from "../../../Redux/action/picture.action";
import QRCodeGenerator from "../../../components/QRCode";
import { qrCodeURL } from "../../../util/uitil";
import ErrorCard from "../../../components/Common/ErrorCard";

interface AddEditPictureFormProps {
  isEdit: boolean;
  pictureId: string | null;
  onClose: () => void;
}

const AddEditPictureForm: React.FC<AddEditPictureFormProps> = ({
  isEdit,
  pictureId,
  onClose,
}) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [formData, setFormData] = useState({
    title: "",
    text: "",
    pictures: [] as File[],
    captions: "",
    status: false,
  });
  const [picturePreviews, setPicturePreviews] = useState<string[]>([]);
  const _sharePictureById = useSelector(
    (state: { sharePictureById: any }) => state.sharePictureById
  );
  const { loading: picLoading, sharePicture, error } = _sharePictureById;

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const toastPositions = useSelector((state: any) => state.toastPosition);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  useEffect(() => {
    if (isEdit && pictureId) {
      dispatch<any>(
        getSharePictureById(pictureId ? pictureId : "", toastPositions, toast)
      );
    }
  }, [dispatch, pictureId, isEdit]);

  useEffect(() => {
    if (isEdit && sharePicture) {
      setFormData({
        title: sharePicture.title,
        text: sharePicture.text,
        pictures: [],
        captions: sharePicture.captions.join(", "),
        status: sharePicture.status,
      });
      setPicturePreviews(sharePicture.pictures);
    }
  }, [sharePicture, isEdit]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: checked }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ? Array.from(e.target.files) : [];
    if (files.length > 0) {
      const newPreviews = files.map((file) => URL.createObjectURL(file));
      setFormData((prevData) => ({
        ...prevData,
        pictures: [...prevData.pictures, ...files],
      }));
      setPicturePreviews([...picturePreviews, ...newPreviews]);
    }
  };

  const handleRemoveImage = (index: number) => {
    const newPreviews = [...picturePreviews];
    newPreviews.splice(index, 1);
    setPicturePreviews(newPreviews);

    const newPictures = [...formData.pictures];
    newPictures.splice(index, 1);
    setFormData((prevData) => ({ ...prevData, pictures: newPictures }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const form = new FormData();
    form.append("title", formData.title);
    form.append("text", formData.text);
    form.append("captions", formData.captions);
    formData.pictures.forEach((picture) => form.append("pictures", picture));
    form.append("status", JSON.stringify(formData.status));

    const onUploadProgress = (progressEvent: any) => {
      const { loaded, total } = progressEvent;
      setUploadProgress(Math.round((loaded * 100) / total));
    };

    if (isEdit) {
      dispatch<any>(
        updateSharePicture(
          pictureId ? pictureId : "",
          form,
          toastPositions,
          toast,
          onUploadProgress,
          onClose
        )
      );
    } else {
      dispatch<any>(
        createSharePicture(
          form,
          toastPositions,
          toast,
          onUploadProgress,
          onClose
        )
      );
    }
  };

  const handleDropClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && !picLoading && (
        <Flex
          height={"50vh"}
          width={"full"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box width={"200px"}>
            <ErrorCard message={error} />
          </Box>
        </Flex>
      )}

      {!error && !picLoading ? (
        <Stack spacing={5}>
          <FormControl>
            <FormLabel mb={0} fontSize="lg" htmlFor="title">
              Title
            </FormLabel>
            <FormHelperText mb={2} mt={0} id="title-helptext">
              Add title for this doc
            </FormHelperText>
            <Input
              id="title"
              name="title"
              placeholder="add title here"
              value={formData.title}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl>
            <FormLabel mb={0} fontSize="lg" htmlFor="text">
              Text
            </FormLabel>
            <FormHelperText mb={2} mt={0} id="text-helptext">
              Add text like a memory or anything that describes the picture
            </FormHelperText>
            <Textarea
              id="text"
              name="text"
              placeholder="add text here"
              value={formData.text}
              onChange={handleInputChange}
              rows={3}
              aria-describedby="text-helptext"
            />
          </FormControl>

          <FormControl>
            <FormLabel mb={0} fontSize="lg" htmlFor="captions">
              Captions
            </FormLabel>
            <FormHelperText mb={2} mt={0} id="captions-helptext">
              Add Captions here for the picture
            </FormHelperText>
            <Input
              id="captions"
              name="captions"
              placeholder="add captions here"
              value={formData.captions}
              onChange={handleInputChange}
            />
          </FormControl>

          {pictureId && (
            <FormControl>
              <FormLabel mb={0} fontSize={"lg"}>
                Qr Code
              </FormLabel>
              <FormHelperText mb={2} mt={0} id="status-helptext">
                Share this qr code and it will display the content only if the
                status is on
              </FormHelperText>

              <QRCodeGenerator url={qrCodeURL(`p/${pictureId}`)} />
            </FormControl>
          )}

          <FormControl position={"unset"}>
            <FormLabel mb={0} fontSize="lg" htmlFor="pictures">
              Pictures
            </FormLabel>
            <FormHelperText mb={2} mt={0} id="pictures-helptext">
              Add Pictures to be shared
            </FormHelperText>
            <Flex
              alignItems="center"
              justifyContent="center"
              borderWidth="1px"
              width="full"
              p="15px"
              my="15px"
              borderRadius="15px"
              onClick={handleDropClick}
              cursor="pointer"
            >
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                multiple
                onChange={handleFileChange}
              />
              <Stack alignItems="center" spacing={1}>
                <Stack direction="row" spacing={2} align="center">
                  <Button colorScheme="teal">
                    <AddIcon fontSize={26} />
                  </Button>
                </Stack>
                <Stack alignItems="center">
                  <Text>or drag and drop</Text>
                  <Text fontSize="sm" color="gray.500">
                    PNG, JPG or GIF up to 2MB
                  </Text>
                </Stack>
              </Stack>
            </Flex>
            {picturePreviews.length > 0 && (
              <Box mt={4}>
                <FormHelperText mb={2} mt={0} id="pictures-preview-helptext">
                  The selected pictures are displayed here
                </FormHelperText>
                <Flex wrap="wrap">
                  {picturePreviews.map((preview, index) => (
                    <Box
                      overflow={"hidden"}
                      borderRadius={"10px"}
                      key={index}
                      mr={2}
                      mb={2}
                      pos={"relative"}
                    >
                      <CloseIcon
                        position={"absolute"}
                        top={"5px"}
                        right={"5px"}
                        cursor="pointer"
                        bgColor={"red.400"}
                        padding={"5px"}
                        borderRadius={"4px"}
                        boxSize={"20px"}
                        onClick={() => handleRemoveImage(index)}
                      />
                      <Image
                        src={preview}
                        alt={`preview-${index}`}
                        objectFit={"cover"}
                        height={"100px"}
                        width="150px"
                      />
                    </Box>
                  ))}
                </Flex>
              </Box>
            )}

            {uploadProgress > 0 && (
              <Box
                className="video"
                position={"absolute"}
                background={"#00000069"}
                backdropFilter={"blur(10px)"}
                borderRadius={"10px"}
                top={"0"}
                height={"95.3%"}
                width={"89.5%"}
                mt={4}
                padding={"10px"}
                display={"flex"}
                flexDir={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                zIndex={11}
                sx={{
                  background:
                    "linear-gradient(350deg, #003b8080 0%, rgba(225, 5, 34, 0) 70%), linear-gradient(135deg, rgb(225 5 152 / 20%) 10%, rgb(49 5 209 / 0%) 80%), linear-gradient(225deg, rgb(22 208 205 / 53%) 10%, rgba(10, 219, 216, 0) 80%), linear-gradient(315deg, #bd05f582 100%, rgba(9, 245, 5, 0) 70%)",
                  backgroundSize: "180% 180%",
                  animation: "gradient-animation 4s ease infinite",
                  "@keyframes gradient-animation": {
                    "0%": {
                      backgroundPosition: "0% 50%",
                    },
                    "50%": {
                      backgroundPosition: "100% 50%",
                    },
                    "100%": {
                      backgroundPosition: "0% 50%",
                    },
                  },
                }}
              >
                {/* <Center> */}
                <Progress
                  hasStripe
                  w={"100%"}
                  colorScheme="green"
                  size={"lg"}
                  borderRadius={"10px"}
                  value={uploadProgress}
                />
                <Center mt={"10px"} fontSize={"20px"} fontWeight={"bold"}>
                  Progress {uploadProgress}%
                </Center>
                {/* </Center> */}
              </Box>
            )}
          </FormControl>

          <FormControl>
            <FormLabel mb={0} fontSize="lg">
              Status
            </FormLabel>
            <FormHelperText mb={2} mt={0} id="status-helptext">
              On means this is shared and available for public.
            </FormHelperText>
            <Switch
              colorScheme="teal"
              name="status"
              isChecked={formData.status}
              onChange={handleSwitchChange}
            />
          </FormControl>
        </Stack>
      ) : (
        <Loading />
      )}

      <ModalFooter>
        <Button mr={3} px={"25px"} type="submit" colorScheme="teal">
          Save
        </Button>
        <Button onClick={onClose}>Close</Button>
      </ModalFooter>
    </form>
  );
};
export default AddEditPictureForm;
