import {
  useToast,
  Grid,
  HStack,
  Button,
  Box,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loading, NoData } from "../../../components/Common/Components";
import PageHeader from "../../../components/PageHeader";
import {
  deleteShareText,
  getAllShareTexts,
} from "../../../Redux/action/text.actions";
import { BsFileText } from "react-icons/bs";
import TextCard from "./TextCard";
import ErrorCard from "../../../components/Common/ErrorCard";
import useDocumentTitle from "../../../interceptor/useDocumentTitle";

const TextAdminPage = () => {
  useDocumentTitle("Text");
  const PAGE_SIZE = 6;
  const [data, setData] = useState<any[]>([]); // set initial data
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [shareTextToDelete, setshareTextToDelete] = useState<any>(null);

  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();

  const _allShareTexts = useSelector(
    (state: { allShareTexts: any }) => state.allShareTexts
  );
  const { loading: shareTextLoading, shareTexts, error } = _allShareTexts;

  const _deleteShareText = useSelector(
    (state: { deleteShareText: any }) => state.deleteShareText
  );
  const { loading: shareTextDeleteLoading, shareText: DeleteshareText } =
    _deleteShareText;

  const toastPositions = useSelector((state: any) => state.toastPosition);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleOpenNewModal = () => {
    navigate("/v1/text/add");
  };

  const handleDelete = (shareText: any) => {
    setshareTextToDelete(shareText);
    setDeleteConfirmationOpen(true);
  };

  const handleConfirmDelete = () => {
    // Perform the delete action here
    if (shareTextToDelete) {
      dispatch<any>(
        deleteShareText(shareTextToDelete._id, toastPositions, toast)
      );
    }
    // Close the delete confirmation modal
    setDeleteConfirmationOpen(false);
  };

  useEffect(() => {
    dispatch<any>(getAllShareTexts(toastPositions, toast));
  }, [dispatch, DeleteshareText, toast]);

  useEffect(() => {
    const startIndex = (page - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;

    setData(shareTexts);
    setFilteredData(
      data
        ?.filter((d) =>
          d.title?.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .slice(startIndex, endIndex)
    );
  }, [shareTexts, data, shareTextLoading, searchTerm, page]);

  return (
    <>
      <PageHeader
        onSearchChange={handleSearchChange}
        onButtonClick={handleOpenNewModal}
        searchTerm={searchTerm}
        listLength={data?.length}
        name="Text"
        button={true}
      />

      {error ? (
        <Flex
          height={"50vh"}
          width={"full"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box width={"200px"}>
            <ErrorCard message={error} />
          </Box>
        </Flex>
      ) : (
        <>
          {(shareTextLoading || shareTextDeleteLoading) && <Loading />}
          {!(shareTextLoading || shareTextDeleteLoading) &&
          filteredData?.length > 0 ? (
            <>
              <Grid
                templateColumns="repeat(auto-fill, minmax(280px, 1fr))"
                gap={6}
              >
                {filteredData?.map((shareText, index) => (
                  <TextCard
                    onDelete={handleDelete}
                    shareText={shareText}
                    key={index}
                  />
                ))}
              </Grid>
              {data?.length > PAGE_SIZE && (
                <HStack mt={6}>
                  {Array.from(
                    Array(Math.ceil(data?.length / PAGE_SIZE)),
                    (_, i) => i + 1
                  ).map((p) => (
                    <Button
                      key={p}
                      variant={page === p ? "solid" : "outline"}
                      size="sm"
                      onClick={() => handlePageChange(p)}
                    >
                      {p}
                    </Button>
                  ))}
                </HStack>
              )}
            </>
          ) : (
            !(shareTextLoading || shareTextDeleteLoading) && (
              <NoData icon={BsFileText} />
            )
          )}
        </>
      )}

      {/* delete */}
      <Modal
        isOpen={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        size="sm"
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          backdropFilter={"blur(20px)"}
          background={useColorModeValue("whiteAlpha.500", "blackAlpha.600")}
          borderWidth={"1px"}
          borderRadius={"15px"}
        >
          <ModalHeader>Delete Text File</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete this Text File?</Text>
          </ModalBody>
          <ModalFooter gap={"10px"}>
            <Button colorScheme="red" onClick={handleConfirmDelete}>
              Delete
            </Button>
            <Button onClick={() => setDeleteConfirmationOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TextAdminPage;
