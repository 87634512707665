import { ReactNode } from "react";
import Footer from "./Footer";
import NavBar from "./NavBar";

interface NonLoginLayoutProps {
  children: ReactNode;
}

const NonLoginLayout: React.FC<NonLoginLayoutProps> = ({ children }) => {
  return (
    <>
      <NavBar backdropFilter={"blur(20px)"} position={"sticky"} top={"0"} />{" "}
      {children}
      <Footer />
    </>
  );
};

export default NonLoginLayout;
